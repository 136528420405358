import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Formik } from "formik";
import * as Yup from "yup";
import { MyInput } from "../ui";
import Photo from "../assets/signup.jpg";
import Axios from "../Axios";
import LoginModal from "../modals/LoginModal/LoginModal";
import Popup from "../utils/Popup/Popup";

const logo = require("../assets/logo-with-bottom-name.png");

const _schemas = Yup.object().shape({
  firstname: Yup.string().required("Хоосон байна!"),
  lastname: Yup.string().required("Хоосон байна!"),
  phone_number: Yup.string()
    .matches(/^[0-9]*$/, "Зөвхөн тоо бичнэ үү!")
    .min(8, "Урт багадаа 8 байх ёстой!")
    .max(8, "8-аас их байж болохгүй!")
    .required("Хоосон байна!"),
  email: Yup.string()
    .email("Зөв форматаар бичнэ үү!")
    .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Зөв форматаар бичнэ үү!")
    .required("Хоосон байна!"),
  citizenship: Yup.string().required("Хоосон байна!"),
  password: Yup.string()
    .min(8, "Хамгийн багадаа 8 тэмдэгт байх ёстой!")
    .required("Хоосон байна!"),
  repassword: Yup.string()
    .oneOf([Yup.ref("password")], "Таарахгүй байна!")
    .required("Хоосон байна!"),
});

const SignUp = () => {
  const navite = useNavigate();

  const [visibleLogin, setVisibleLogin] = useState(false);

  const [popupType, setPopupType] = useState("");
  const [popupText, setPopupText] = useState("");
  const [visiblePopup, setVisiblePopup] = useState(false);

  const signupHandler = (values) => {
    Axios.post("/auth/signup", values)
      .then((res) => {
        if (res.data.message === "success") {
          setPopupType("success");
          setPopupText("Та амжилттай бүртгүүллээ");
          setVisiblePopup(true);
        }
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500) {
          setPopupType("error");
          setPopupText(err.response.data.message);
        } else {
          setPopupType("sys_error");
          setPopupText("Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!");
        }

        setVisiblePopup(true);
      });
  };

  const popupOnOK = () => {
    if (String(popupType).includes("error")) {
      window.location.reload();
    } else {
      setVisiblePopup(false);

      setVisibleLogin(true);
    }
  };

  return (
    <main className="signup">
      <LoginModal visible={visibleLogin} setVisible={setVisibleLogin} />

      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={popupOnOK}
      />

      <img className="signup__cover" src={Photo} alt="no file" />

      <div className="signup__content">
        <button
          className="no-button signup__content-goBack"
          onClick={() => navite(-1)}
        >
          <IoIosArrowBack /> Буцах
        </button>

        <img className="signup__content-logo" src={logo} alt="no file" />

        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            phone_number: "",
            email: "",
            citizenship: "",
            password: "",
            repassword: "",
          }}
          validationSchema={_schemas}
          onSubmit={(vals) => signupHandler(vals)}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            handleChange,
            handleSubmit,
          }) => (
            <form className="signup__content-form" onSubmit={handleSubmit}>
              <span className="signup__content-form-row">
                <MyInput
                  label="Овог"
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("lastname")}
                  touched={touched.lastname}
                  errorText={errors.lastname}
                />

                <MyInput
                  label="Нэр"
                  name="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("firstname")}
                  touched={touched.firstname}
                  errorText={errors.firstname}
                />
              </span>

              <MyInput
                label="Утасны дугаар"
                name="phone_number"
                value={values.phone_number}
                onChange={handleChange}
                onBlur={() => setFieldTouched("phone_number")}
                touched={touched.phone_number}
                errorText={errors.phone_number}
              />

              <MyInput
                label="И-мэйл хаяг"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={() => setFieldTouched("email")}
                touched={touched.email}
                errorText={errors.email}
              />
              <div className="signup__content-form-row-text">Иргэншил</div>
              <select
                label="Иргэншил"
                name="citizenship"
                value={values.citizenship}
                onChange={handleChange}
                onBlur={() => setFieldTouched("citizenship")}
                touched={touched.citizenship}
                errorText={errors.citizenship}
              >
                <option value="">-- сонгох --</option>
                <option value="Монгол">Монгол</option>
                <option value="Бусад">Бусад</option>
              </select>

              <MyInput
                label="Нууц үг"
                name="password"
                value={values.password}
                type="password"
                onChange={handleChange}
                onBlur={() => setFieldTouched("password")}
                touched={touched.password}
                errorText={errors.password}
              />

              <MyInput
                label="Нууц үг давтах"
                name="repassword"
                value={values.repassword}
                type="password"
                onChange={handleChange}
                onBlur={() => setFieldTouched("repassword")}
                touched={touched.repassword}
                errorText={errors.repassword}
              />

              {/* <div className='signup__content-form-checkText'>
                <Checkbox
                  id='readTos'
                  name='readTos'
                  onChange={(e) =>
                    console.log(`${e.target.name}:`, e.target.checked)
                  }
                >
                  Би 18 нас хүрсэн бөгөөд <b>үйлчилгээний нөхцөл</b>-тэй
                  танилцсан.
                </Checkbox>

                <Checkbox
                  id='readPrivacy'
                  name='readPrivacy'
                  onChange={(e) =>
                    console.log(`${e.target.name}:`, e.target.checked)
                  }
                >
                  <b>Нууцлалын бодлого</b>-той уншиж танилцсан.
                </Checkbox>
              </div> */}

              <button type="submit">Бүртгүүлэх</button>
            </form>
          )}
        </Formik>

        <div className="signup__content-bottom">
          Бүртгэлтэй хэрэглэгч үү?
          <button
            className="no-button signup__content-bottom-loginBtn"
            type="button"
            onClick={() => setVisibleLogin(true)}
          >
            Нэвтрэх
          </button>
        </div>
      </div>
    </main>
  );
};

export default SignUp;

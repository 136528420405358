import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from '../Axios';
import Popup from '../utils/Popup/Popup';

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const AUTH_KEY = 'USER_TOKEN';

  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [authConfig, setAuthConfig] = useState(null);

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem(AUTH_KEY);

    if (token) {
      setIsLogin(true);
      setAuthConfig({ headers: { token } });
    } else {
      setIsLogin(false);
      setAuthConfig(null);
    }
  }, []);

  function loginHandler({ email, password, doReload = false }) {
    setIsLoading(true);

    Axios.post('/auth/login', { email, password })
      .then((res) => {
        if (res.data) {
          const { token } = res.data;
          localStorage.setItem(AUTH_KEY, token);

          setIsLogin(true);
          doReload
            ? window.location.reload()
            : window.location.replace('/account');

          setAuthConfig({ headers: { token } });
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
          setVisiblePopup(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setPopupType('error');
          setPopupText(err.response.data.message);
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        }

        setVisiblePopup(true);
      });
  }

  const recoveryHandler = ({ email, password }) => {
    setIsLoading(true);

    Axios.put('/auth/recovery-pass', { email, password })
      .then((res) => {
        if (res.data.message === 'success') {
          setPopupType('success');
          setPopupText('Таны нууц үг амжилттай сэргээгдлээ.');
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        }

        setVisiblePopup(true);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setPopupType('error');
          setPopupText(err.response.data.message);
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        }

        setVisiblePopup(true);
      });
  };

  const logoutHandler = () => {
    localStorage.removeItem(AUTH_KEY);
    setIsLogin(false);

    navigate('/');
  };

  const popupOnOK = () => {
    if (popupType === 'error') {
      setIsLoading(false);
      setVisiblePopup(false);
    } else {
      window.location.reload();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isLogin,
        authConfig,
        loginHandler,
        recoveryHandler,
        logoutHandler,
      }}
    >
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={popupOnOK}
      />

      {children}
    </AuthContext.Provider>
  );
};

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { HiDotsVertical, HiOutlineClipboardList } from "react-icons/hi";
// import { AiFillLike, AiFillDislike } from "react-icons/ai";
import ReactPlayer from "react-player";
import { useAuthContext } from "../../context/AuthContext";
import Axios from "../../Axios";
import Layout from "../../utils/Layouts/Layout/Layout";
import PayModal from "../../modals/PayModal/PayModal";
import Popup from "../../utils/Popup/Popup";

const noFile = require("../../assets/no-file.png");
const noIMG = require("../../assets/frame.png");
const courseBanner = require("../../assets/course_banner.png");

const CourseDetail = () => {
  const { id } = useParams();
  const { isLogin, authConfig } = useAuthContext();

  const lessonsRef = useRef(null);
  const videoRef = useRef();

  const [data, setData] = useState({});
  const [lessons, setLessons] = useState([]);
  const [isPayed, setIsPayed] = useState(false);

  const [visiblePay, setVisiblePay] = useState(false);
  const [visibleNotPay, setVisibleNotPay] = useState(false);

  const [visibleLessons, setVisibleLessons] = useState(false);
  const [activeIdx, setActiveIdx] = useState(null);

  useEffect(() => {
    const menuHandler = (e) => {
      if (!lessonsRef.current?.contains(e.target)) {
        setVisibleLessons(false);
      }
    };
    document.addEventListener("click", menuHandler);

    return () => document.removeEventListener("click", menuHandler);
  }, []);

  useEffect(() => {
    videoRef.current?.load();
  }, [activeIdx]);

  useEffect(() => {
    if (id) {
      Axios.get(`/courses/${id}`).then((res) => {
        if (res.status === 200) {
          setData(res.data);

          if (res.data?.is_free) {
            setActiveIdx(0);
          }
        }
      });

      Axios.get(`/course_lessons/${id}/list`).then((res) => {
        if (res.status === 200) {
          setLessons(res.data);
        }
      });

      if (authConfig) {
        Axios.get(`/users_courses/payed/${id}`, authConfig).then((res) => {
          if (res.status === 200) {
            setIsPayed(res.data.is_payed);
          } else {
            setIsPayed(false);
          }
        });
      }
    }
  }, [id, authConfig]);

  const renderLessons = () => {
    if (typeof activeIdx === "number" || data?.is_free) {
      if (lessons[activeIdx]?.video) {
        return (
          <div
            className="courseDetail__content-videoZone-player"
            dangerouslySetInnerHTML={{
              __html: lessons[activeIdx].video,
            }}
          />
        );
      } else {
        return (
          <div className="courseDetail__content-videoZone-player">
            Уучлаарай бичлэг байхгүй байна
          </div>
        );
      }
    } else {
      return (
        <ReactPlayer
          width="100%"
          url="https://www.youtube.com/watch?v=hRGMe4kEST8"
        />
      );
    }
  };

  return (
    <Layout>
      <PayModal id={id} visible={visiblePay} setVisible={setVisiblePay} />

      <Popup
        visible={visibleNotPay}
        messageType="success"
        messageText="Та эхлээд бүртгүүлнэ үү. Бүртгэлтэй бол нэвтэрнэ үү."
        onOk={() => setVisibleNotPay(false)}
        onCancel={() => setVisibleNotPay(false)}
      />

      <div className="courseDetail">
        <img
          className="courseDetail__banner"
          src={courseBanner}
          alt="no file"
        />

        <div className="courseDetail__content">
          <div className="courseDetail__content-videoZone">
            {renderLessons()}

            <span className="courseDetail__content-videoZone-vidInfo">
              <p className="courseDetail__content-videoZone-vidInfo-title">
                {data.title}
              </p>

              <span className="courseDetail__content-videoZone-vidInfo-icons">
                {/* <button className='no-button vidInfo_likesIcon'>
                  <AiFillLike size={26} color='white' />
                  <label>{lessons[activeIdx]?.like_count}</label>
                </button>
                <button className='no-button vidInfo_likesIcon'>
                  <AiFillDislike size={26} color='gray' />
                  <label>{lessons[activeIdx]?.dislike_count}</label>
                </button> */}

                <button className="no-button ">
                  <HiDotsVertical size={26} color="white" />
                </button>
              </span>
            </span>

            <span className="courseDetail__content-videoZone-userInfo">
              <div className="videoZone_profile">
                <img
                  className="videoZone_profile-img"
                  src={noIMG}
                  alt="no file"
                  onError={(event) => {
                    event.target.onerror = null;
                    event.target.src = noFile;
                  }}
                />

                <div className="videoZone_profile-username">
                  <span>Багш</span>
                  <label>
                    {/* {String(data.teacher_lname).slice(0, 1) +
                      ". " +
                      data.teacher_fname} */}
                    Баярсайхан Пүрэв-Очир (Admin)
                  </label>
                </div>
              </div>

              {/* <span className='videoZone_followers'>5.57 мянган захиалагч</span> */}
            </span>
          </div>

          {data?.is_free || (isLogin && isPayed) ? (
            <div className="courseDetail__content-lessons">
              <h3 className="courseDetail__content-lessons-title">
                Онлайн сургалтын хичээлүүд
              </h3>

              <div
                ref={lessonsRef}
                className="courseDetail__content-lessons-list"
              >
                <button
                  className="lessons_btn"
                  onClick={() => setVisibleLessons((prev) => !prev)}
                >
                  <HiOutlineClipboardList size={26} />
                </button>

                <div
                  className={`lessons_list ${
                    visibleLessons ? "lessons_shown" : "lessons_hidden"
                  }`}
                >
                  {lessons?.length > 0 &&
                    lessons.map((item, idx) => (
                      <button
                        key={item.id}
                        className={`no-button lessons_list-item ${
                          activeIdx === idx ? "activeCourse" : ""
                        }`}
                        onClick={() => setActiveIdx(idx)}
                      >
                        <label>{String(idx + 1).padStart(2, "0")}.</label>
                        {item.title}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="courseDetail__content-beforePay">
              <div className="courseDetail__content-beforePay-box">
                <span>
                  <h3>Та юу сурах вэ?</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  />
                </span>

                <span>
                  <h3>Шаардлага</h3>
                  <div>
                    Уг төлбөртэй хичээлийг үзэхийн тулд та заавал бүртгүүлсэн
                    байх шаардлагатай.
                  </div>
                </span>
              </div>

              <div className="courseDetail__content-beforePay-box">
                <button
                  onClick={() =>
                    isLogin ? setVisiblePay(true) : setVisibleNotPay(true)
                  }
                >
                  Сургалт Үзэх
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CourseDetail;

import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import './myInput.scss';

/*
  input types:
  - email
  - password
  - number
*/

const MyInput = ({
  id,
  label = '',
  icon,
  name,
  placeholder = '',
  value,
  defaultValue,
  type = '',
  onChange,
  onBlur,
  touched,
  errorText,
  disabled = false,
  fillInput = false,
  isTextarea = false,
  rows,
}) => {
  const [shownPassword, setShownPassword] = useState(false);

  const renderInput = () => {
    let _type = '';

    if (type === 'password') {
      _type = shownPassword ? 'text' : 'password';
    } else {
      _type = type;
    }

    return isTextarea ? (
      <textarea
        className={`${fillInput && 'fill-input'}`}
        name={name}
        value={value}
        defaultValue={defaultValue}
        rows={rows}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    ) : (
      <input
        id={id}
        className={`${fillInput && 'fill-input'}`}
        name={name}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        type={_type}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  return (
    <div className='myInput'>
      {label && <label className='myInput__label'>{label}:</label>}

      {icon || type === 'password' ? (
        <div
          className={`myInput__withIcon ${fillInput && 'fillInput'} ${
            disabled && 'disabled_input'
          }`}
        >
          {icon && <div className='myInput__withIcon-left'>{icon}</div>}

          {renderInput()}

          {type === 'password' && (
            <button
              className='myInput__withIcon-right'
              type='button'
              onClick={() => setShownPassword((val) => !val)}
            >
              {shownPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          )}
        </div>
      ) : (
        renderInput()
      )}

      {touched && errorText && (
        <label className='myInput__errorText'>{errorText}</label>
      )}
    </div>
  );
};

export default MyInput;

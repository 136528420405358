import React from 'react';
import moment from 'moment';
import Table from '../../utils/Table/Table';

const ApprovedCourses = () => {
  const columns = [
    {
      Header: 'Сургалтын гарчиг',
      accessor: 'course_title',
    },
    {
      Header: 'Хэрэглэгчийн овог',
      accessor: 'lastname',
    },
    {
      Header: 'Хэрэглэгчийн нэр',
      accessor: 'firstname',
    },
    {
      Header: 'Авсан огноо',
      accessor: 'payed_date',
      disableFilters: true,
      Cell: ({ value }) => (
        <p style={{ width: 160 }}>{moment(value).format('YYYY/MM/DD HH:mm')}</p>
      ),
    },
  ];

  return (
    <Table
      apiPath='/users_courses'
      apiSubPath='payeds'
      columns={columns}
      disableActions
    />
  );
};

export default ApprovedCourses;

import React from 'react';

const noFile = require('../../../assets/no-file.png');

export const RenderImageUploader = ({
  avatar,
  center,
  inputID,
  currentFile,
  haveFile,
  imgWidth,
  handleFileChanger,
}) => {
  return (
    <div
      className='fileUplaoder'
      style={{
        margin: center ? '0 auto' : '',
      }}
    >
      <input
        type='file'
        id={inputID}
        accept='image/jpeg, image/png, image/svg+xml, image/gif'
        onChange={handleFileChanger}
      />
      {avatar || (
        <label
          className='fileUplaoder__label fileUplaoder__label--top'
          htmlFor={inputID}
        >
          Файл сонгох
        </label>
      )}

      {currentFile.name}

      <figure
        style={{ width: imgWidth }}
        className={`fileUplaoder__figure ${
          haveFile || 'fileUplaoder__figure--noIMG'
        } ${avatar && 'fileUplaoder__figure--avatar'}`}
      >
        <img
          className={`fileUplaoder__figure-img ${
            avatar && 'fileUplaoder__figure-img--avatar'
          }`}
          src={currentFile}
          alt='no file'
          onError={(event) => {
            event.target.onerror = null;
            event.target.src = noFile;
          }}
        />
      </figure>

      {avatar && (
        <label
          className='fileUplaoder__label fileUplaoder__label--bottom'
          htmlFor={inputID}
        >
          Зураг сонгох
        </label>
      )}
    </div>
  );
};

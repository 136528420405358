import { useEffect, useState } from 'react';
import { useAdminContext } from '../../context/AdminContext';
import Axios from '../../Axios';
import Header from '../utils/Header/Header';
import Menu from '../utils/Menu/Menu';
import Content from '../utils/Content/Content';

const Admin = () => {
  const { adminConfig, logoutHandler } = useAdminContext();

  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState(0);

  useEffect(() => {
    if (adminConfig) {
      Axios.get('/auth/me', adminConfig).then((res) => {
        if (res.status === 200) {
          setRole(res.data.role_id);
          setIsLoading(false);
        }
      });
    }
  }, [adminConfig]);

  return (
    isLoading || (
      <main className='admin'>
        <Header role={role} logoutHandler={logoutHandler} />
        <Menu role={role} />
        <Content role={role} />
      </main>
    )
  );
};

export default Admin;

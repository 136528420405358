import React, { useState } from 'react';
import moment from 'moment';
import { useAdminContext } from '../../../context/AdminContext';
import Axios from '../../../Axios';
import Table from '../../utils/Table/Table';
import Popup from '../../../utils/Popup/Popup';

const CourseRequests = () => {
  const { adminConfig } = useAdminContext();

  const [IDs, setIDs] = useState({});
  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  const columns = [
    {
      Header: 'Сургалтын гарчиг',
      accessor: 'course_title',
    },
    {
      Header: 'Хэрэглэгчийн овог',
      accessor: 'lastname',
    },
    {
      Header: 'Хэрэглэгчийн нэр',
      accessor: 'firstname',
    },
    {
      Header: 'Ирсэн огноо',
      accessor: 'created_at',
      disableFilters: true,
      Cell: ({ value }) => moment(value).format('YYYY/MM/DD HH:mm'),
    },
    {
      Header: ' ',
      disableSortBy: true,
      disableFilters: true,
      accessor: (data) => (
        <button
          onClick={() =>
            shownAlert({
              userID: data.user_id,
              fname: data.firstname,
              lname: data.lastname,
              courseID: data.course_id,
              courseTitle: data.course_title,
            })
          }
        >
          Зөвшөөрөх
        </button>
      ),
    },
  ];

  const shownAlert = ({ userID, fname, lname, courseID, courseTitle }) => {
    setIDs({ userID, courseID });

    setPopupType('alert');
    setPopupText(
      'Та' +
        ` "${String(lname).slice(0, 1)}. ${fname}" ` +
        `хэрэглэгчийн "${courseTitle}" хичээлийг худалдаж авах хүсэлтийг зөвшөөрөх гэж байна?`
    );
    setVisiblePopup(true);
  };

  const approveHandler = () => {
    if (Object.keys(IDs).length > 0) {
      const { userID, courseID } = IDs;

      Axios.put('/users_courses/approve', { userID, courseID }, adminConfig)
        .then((res) => {
          if (res.data.message === 'success') {
            window.location.reload();
          } else {
            setPopupType('sys_error');
            setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
            setVisiblePopup(true);
          }
        })
        .catch(() => {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
          setVisiblePopup(true);
        });
    }
  };

  const popupOnCancel = () => {
    if (String(popupType).includes('error')) {
      window.location.reload();
    } else {
      setVisiblePopup(false);
    }
  };

  return (
    <>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={approveHandler}
        onCancel={popupOnCancel}
      />

      <Table
        apiPath='/users_courses'
        apiSubPath='requests'
        columns={columns}
        disableActions
      />
    </>
  );
};

export default CourseRequests;

import React from 'react';
import CourseList from '../../components/CourseList/CourseList';

const MyCourses = () => {
  return (
    <div className='acntContent'>
      <h3 className='acntContent__heading'>Миний хичээлүүд</h3>

      <CourseList type='advanced' inUserCourses />
    </div>
  );
};

export default MyCourses;

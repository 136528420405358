import React from 'react';

import './loader.scss';

const _logo = require('../../assets/logo.png');

const Loader = ({ mini = false, noCenter = false, wrapperSize }) => {
  return (
    <div
      className={`loader ${mini && 'mini'}`}
      style={{
        margin: noCenter || '0 auto',
        ...(wrapperSize && { height: wrapperSize }),
      }}
    >
      {mini || <img className='loader__logo' src={_logo} alt='no file' />}
    </div>
  );
};

export default Loader;

import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '../../ui';

import './payModal.scss';

const logo = require('../../assets/logo.png');
const golomt = require('../../assets/bank-golomt-logo.png');
const khan = require('../../assets/bank-khan-logo.png');
const tdb = require('../../assets/bank-tdb-logo.png');
const picture = require('../../assets/paymodal.png');

const PayModal = ({ id, visible, setVisible }) => {
  return (
    <Modal visible={visible} onCancel={setVisible}>
      <div className='payModal'>
        <div className='payModal__content'>
          <img className='payModal__content-logo' src={logo} alt='no file' />

          <ul className='payModal__content-banks'>
            <li>
              <img src={golomt} alt='no file' />
            </li>
            <li>
              <img src={khan} alt='no file' />
            </li>
            <li>
              <img src={tdb} alt='no file' />
            </li>
          </ul>
          <div className='payModal__content-text'>
            Та төлбөртэй хичээлийн төлбөр төлөх хэсэг рүү очихийг хүсвэл доорх
            товч дээр дарна уу
          </div>

          <Link
            className='payModal__content-payBtn'
            to={`/courses/${id}/payout`}
          >
            Төлбөрийн хэсэгт очих
          </Link>

          {/* <button className='no-button payModal__content-pdfBtn'>
            Гэрээ байгуулах PDF
          </button> */}
        </div>

        <img className='payModal__cover' src={picture} alt='no file' />
      </div>
    </Modal>
  );
};

export default PayModal;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RiShutDownLine } from 'react-icons/ri';
import { ROUTES } from '../../routes';
import { getValueFromTree } from '../../../utils/Functions';
import Popup from '../../../utils/Popup/Popup';

import './header.scss';

const logo = require('../../../assets/logo-with-right-name.png');

const Header = ({ role, logoutHandler }) => {
  const { pathname } = useLocation();
  const [visibleAlert, setVisibleAlert] = useState(false);

  let ROLE_NAME;
  switch (role) {
    case 1:
      ROLE_NAME = 'Admin';
      break;
    case 2:
      ROLE_NAME = 'Sub Admin';
      break;
    case 3:
      ROLE_NAME = 'Social Admin';
      break;
    default:
      ROLE_NAME = '';
  }

  function getMenuItemHeading() {
    return ROUTES({ role }).map((item) =>
      getValueFromTree(item, pathname, 'label', 'heading')
    );
  }

  return (
    <header className='admHeader'>
      <Popup
        visible={visibleAlert}
        messageType='alert'
        messageText='Та гарахдаа итгэлтэй байна уу?'
        onOk={logoutHandler}
        onCancel={() => setVisibleAlert(false)}
      />

      <div className='admHeader__heading'>
        <figure className='admHeader__heading-logo'>
          <Link to='/'>
            <img src={logo} alt='no file' />
          </Link>
        </figure>
      </div>

      <div className='admHeader__content'>
        <b className='admHeader__content-title'>{getMenuItemHeading()}</b>

        <div className='admHeader__content-right'>
          <span className='admHeader__content-right-roleName'>{ROLE_NAME}</span>

          <button
            className='no-button admHeader__content-right-logout'
            onClick={() => setVisibleAlert(true)}
          >
            <RiShutDownLine color='white' />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { ROUTES } from '../../routes';
import MenuItem from './MenuItem';

import './menu.scss';

const Menu = ({ role }) => {
  return (
    <aside className='adm_menu_aside'>
      <nav className='adm_menu_nav'>
        <ul className='menu'>
          {ROUTES({ role }).map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default Menu;

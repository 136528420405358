import Layout from '../utils/Layouts/Layout/Layout';

const NotFound = () => {
  return (
    <Layout>
      <div style={{}}>
        <h1>Хуудас олдсонгүй...</h1>
      </div>
    </Layout>
  );
};
export default NotFound;

import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useAdminContext } from "./context/AdminContext";
import { useAuthContext } from "./context/AuthContext";

import Admin from "./admin/pages/Admin";
import AdminLogin from "./admin/pages/AdminLogin";

import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import CourseTypes from "./pages/Courses/CourseTypes";
import Courses from "./pages/Courses/Courses";
import CourseDetail from "./pages/Courses/CourseDetail";
import CoursePayout from "./pages/Courses/CoursePayout";
import Mentorship from "./pages/Mentorship";
import Socials from "./pages/Socials/Socials";
import News from "./pages/News/News";
import NewsDetail from "./pages/News/NewsDetail";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Scholarship from "./pages/Scholarships/Scholarship";
import ScholarshipDetail from "./pages/Scholarships/ScholarshipDetail";
import Account from "./pages/Account/Account";

function App() {
  const { isLogin: isAdminAuth } = useAdminContext();
  const { isLogin } = useAuthContext();

  const [device, setDevice] = useState("");

  useEffect(() => {
    // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //   navigator.userAgent
    // )
    //
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      setDevice("mobile");
    } else {
      setDevice("web");
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/signup" element={<SignUp />} />
      {isLogin && <Route path="/account/*" element={<Account />} />}

      <Route path="/courses" element={<CourseTypes />} />
      <Route path="/courses/:type/list" element={<Courses />} />
      <Route path="/courses/:id" element={<CourseDetail />} />
      <Route path="/courses/:id/payout" element={<CoursePayout />} />

      <Route path="/mentorship" element={<Mentorship />} />
      <Route path="/channels/*" element={<Socials />} />
      <Route path="/scholarship" element={<Scholarship />} />
      <Route path="/scholarship/:id" element={<ScholarshipDetail />} />

      <Route path="/news" element={<News />} />
      <Route path="/news/:id" element={<NewsDetail />} />

      <Route path="/contact" element={<Contact />} />

      {device === "web" && (
        <Route
          path="/admin/*"
          element={isAdminAuth ? <Admin /> : <AdminLogin />}
        />
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;

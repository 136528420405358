import { useEffect, useState } from 'react';
import { MdDeleteForever, MdRemoveRedEye } from 'react-icons/md';
import { useAdminContext } from '../../../context/AdminContext';
import Axios from '../../../Axios';
import Modal from '../../../ui/Modal/Modal';

import './lessonsModal.scss';

const LessonsModal = ({ visible, courseID, showAddLesson, onCancel }) => {
  // const videoRef = useRef();

  const { adminConfig } = useAdminContext();

  const [data, setData] = useState([]);
  const [videoURL, setVideoURL] = useState('');
  const [visibleVideo, setVisibleVideo] = useState(false);

  useEffect(() => {
    if (visible) {
      // videoRef.current?.load();

      Axios.get(`/course_lessons/${courseID}/list`).then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      });
    }
  }, [
    visible,
    courseID,
    // videoURL
  ]);

  const showVideoModal = (url) => {
    setVideoURL(url);
    setVisibleVideo(true);
  };

  const deleteHandler = (id) => {
    Axios.delete(`/course_lessons/${id}/`, adminConfig).then((res) => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  };

  const cancelVideoModal = () => {
    setVideoURL('');
    setVisibleVideo(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
    >
      <Modal
        visible={visibleVideo}
        onCancel={cancelVideoModal}
      >
        {/* <video className='lesson__video' ref={videoRef} controls autoPlay>
          <source src={videoURL} />
        </video> */}

        <div
          className='lesson__video'
          dangerouslySetInnerHTML={{ __html: videoURL }}
        />
      </Modal>

      <div className='lessonsModal'>
        <div className='lessonsModal__heading'>
          <h3 className='lessonsModal__heading-title'>Сургалтын хичээлүүд</h3>

          <button
            onClick={() => {
              onCancel();
              showAddLesson();
            }}
          >
            Нэмэх
          </button>
        </div>

        <ul className='lessonsModal__list'>
          {Array.isArray(data) &&
            data.map((item, idx) => (
              <li
                key={idx}
                className='lessonsModal__list-item'
              >
                <b>{item.title}</b>

                <div className='lessonsModal__list-item-actions'>
                  <button onClick={() => showVideoModal(item.video)}>
                    <MdRemoveRedEye />
                  </button>

                  <button
                    className='deleteBtn'
                    onClick={() => deleteHandler(item.id)}
                  >
                    <MdDeleteForever />
                  </button>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </Modal>
  );
};

export default LessonsModal;

import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

import './homeLayout.scss';

const HomeLayout = ({ children }) => {
  return (
    <main className='hLayout'>
      <Header onHome />

      {children}

      <Footer />
    </main>
  );
};

export default HomeLayout;

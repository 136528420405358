import Layout from '../utils/Layouts/Layout/Layout';

const Mentorship = () => {
  return (
    <Layout>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'grid',
          placeItems: 'center',
          textAlign: 'center',
          fontWeight: 'bolder',
        }}
      >
        Бид тун удахгүй шинэлэлт оруулах болно🙂
      </div>
    </Layout>
  );
};

export default Mentorship;

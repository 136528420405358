import { useRef } from 'react';
import { IoClose } from 'react-icons/io5';

import './modal.scss';

const Modal = ({ children, visible, onCancel }) => {
  const modalRef = useRef(null);

  const closeOnClick = (e) => {
    if (onCancel && modalRef.current === e.target) {
      onCancel(false);
    }
  };

  return (
    visible && (
      <main ref={modalRef} className='modal' onClick={closeOnClick}>
        <div className='modal__content'>
          <button
            className='no-button modal__content-closeBtn'
            onClick={() => onCancel(false)}
          >
            <IoClose />
          </button>

          {children}
        </div>
      </main>
    )
  );
};

export default Modal;

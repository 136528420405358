import React from "react";
import Layout from "../../utils/Layouts/Layout/Layout";
import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import Youtube from "../../components/Socials/Youtube";
import Telegram from "../../components/Socials/Telegram";

const Socials = () => {
  return (
    <Layout>
      <div className="Socials">
        <div className="Socials__title">Social сувгууд</div>
        <div className="Socials__header">
          <NavLink to="/channels/youtube" className="Socials__header__item">
            0.1 |Youtube|
          </NavLink>
          <NavLink to="/channels/telegram" className="Socials__header__item">
            0.2 |Telegram|
          </NavLink>
        </div>
        <div className="Socials__content">
          <Routes>
            <Route path="/" element={<Navigate to="/channels/youtube" />} />
            <Route path="/youtube" element={<Youtube />} />
            <Route path="/telegram" element={<Telegram />} />
          </Routes>
        </div>
      </div>
    </Layout>
  );
};

export default Socials;

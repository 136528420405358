import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import Axios from '../../../Axios';
import { Carousel, Slide } from '../../../ui/Carousel/Carousel';

import './homeNews.scss';

const noIMG = require('../../../assets/no-image.png');

const HomeNews = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get('/news/list').then((res) => {
      if (res.status === 200) {
        setData(res.data.slice(0, 6));
      }
    });
  }, []);

  /* ======== Item Height ======================================================= */
  const itemRef = useRef(null);
  const [itemHeight, setItemHeight] = useState(0);

  useEffect(() => {
    if (itemRef.current && data?.length > 0) {
      setItemHeight(itemRef.current.getBoundingClientRect().height);
    }
  }, [itemRef, data]);
  /* ============================================================================ */

  return (
    <div className='hNews'>
      <h1 className='hNews__title'>Мэдээ, Мэдээлэл</h1>

      <Carousel
        spaceBetween={20}
        slideCount={3}
        autoplaySpeed={5000}
        autoplay
        infinite
      >
        {Array.isArray(data) &&
          data.map((item, idx) => (
            <Slide key={idx} spaceBetween={20}>
              <div
                ref={itemRef}
                key={item.id}
                className='news__content-item hNewsContent'
              >
                <figure className='news__content-item-cover'>
                  <img src={item.cover ? item.cover : noIMG} alt='no file' />
                </figure>

                <p className='news__content-item-title'>{item.title}</p>

                <div className='news__content-item-content'>
                  <h3 className='news__content-item-content-title'>
                    {item.title}
                  </h3>
                  <div className='news__content-item-content-line' />

                  <div className='news__content-item-content-text'>
                    <TextTruncate
                      text={
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              String(item.content).length > 300
                                ? item.content.substring(0, 300) + '...'
                                : item.content,
                          }}
                        />
                      }
                      line={itemHeight > 320 ? 10 : 6}
                    />
                  </div>

                  <Link
                    className='news__content-item-content-detailLink'
                    to={`/news/${item.id}`}
                  >
                    Дэлгэрэнгүй
                  </Link>
                </div>
              </div>
            </Slide>
          ))}
      </Carousel>
    </div>
  );
};

export default HomeNews;

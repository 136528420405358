import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAdminContext } from '../../../context/AdminContext';
import Axios from '../../../Axios';
import MyInput from '../../../ui/MyInput/MyInput';
import Loader from '../../../utils/Loader/Loader';
import Card from '../../utils/Card/Card';
import Popup from '../../../utils/Popup/Popup';
import FileUploader from '../../utils/FileUploader/FileUploader';
import CKeditor from '../../utils/Ckeditor';

const schema = Yup.object().shape({
  title: Yup.string().required('Хоосон байна!'),
  content: Yup.string().required('Хоосон байна!'),
});

const NewsForm = () => {
  const { id } = useParams();
  const { adminConfig } = useAdminContext();

  const [data, setData] = useState({});
  const [imageFile, setImageFile] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [btnIsLoading, setBtnIsLoading] = useState(false);

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    if (id) {
      Axios.get(`/news/${id}`, adminConfig)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);

          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        });
    } else {
      setIsLoading(false);
      setData({});
    }
  }, [id, adminConfig]);

  const createHandler = (values, formData) => {
    formData.append('creatorID', 'ec71b784-69bf-4d58-bb77-e5c8dddacdd7');
    formData.append('categoryID', 1);
    formData.append('title', values.title);
    formData.append('content', values.content);
    formData.append('cover', data.cover);
    formData.append('imageFile', imageFile);

    Axios.post('/news', formData, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          window.location.replace('/admin/news');
        } else {
          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('');
        setVisiblePopup(true);
      });
  };

  const editHandler = (values, formData) => {
    formData.append('id', id);
    formData.append('creatorID', 'ec71b784-69bf-4d58-bb77-e5c8dddacdd7');
    formData.append('categoryID', 1);
    formData.append('title', values.title);
    formData.append('content', values.content);
    formData.append('cover', data.cover);
    formData.append('imageFile', imageFile);

    Axios.put('/news', formData, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          window.location.reload();
        } else {
          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('');
        setVisiblePopup(true);
      });
  };

  const onSubmit = (values) => {
    setBtnIsLoading(true);

    const formData = new FormData();

    if (id) {
      editHandler(values, formData);
    } else {
      createHandler(values, formData);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card centered>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => window.location.reload()}
      />

      <Formik
        initialValues={{
          title: data.title,
          content: data.content,
        }}
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleSubmit,
        }) => (
          <form className='myForm' onSubmit={handleSubmit}>
            <span className='myForm__row'>
              <label className='myForm__row-label'>Баннер:</label>

              <FileUploader file={data.cover} getFile={setImageFile} />
            </span>

            <span className='myForm__row'>
              <label className='myForm__row-label'>Гарчиг:</label>
              <MyInput
                name='title'
                value={values.title}
                onChange={handleChange}
                onBlur={setFieldTouched}
                touched={touched.title}
                errorText={errors.title}
              />
            </span>

            <span className='myForm__row'>
              <label className='myForm__row-label'>Мэдээ:</label>

              <CKeditor
                onFormik
                name='content'
                setText={setFieldValue}
                text={values.content}
                small
              />
            </span>

            {btnIsLoading ? (
              <Loader mini />
            ) : (
              <button type='submit'>Хадгалах</button>
            )}
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default NewsForm;

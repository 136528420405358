import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../../utils/Table/Table';

const HomeCarousels = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const addButton = (
    <button className='outline-btn' onClick={() => navigate(pathname + '/add')}>
      Нэмэх
    </button>
  );

  const columns = [
    {
      Header: 'Зураг',
      accessor: 'photo',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <Table
      currentHref={pathname}
      apiPath='/fopperies'
      apiSubPath='1'
      addButton={addButton}
      columns={columns}
      squarePhoto
    />
  );
};

export default HomeCarousels;

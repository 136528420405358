import React from "react";
import Picture from "../../assets/youtube.png";

import "./youtube.scss";

const Youtube = () => {
  return (
    <div className="Youtube">
      <div className="Youtube__box">
        <div className="Youtube__box__title">Ochiroo Trader | OT strategy </div>
        <div className="Youtube__box__text">
          Арилжаа сонирхдог, арилжаа хийдэг хүмүүстээ Ochiroo Trader | OT
          strategy сувгаар дамжуулан Education, Scalping trade, Mentorship, Q/A
          зэрэг форекс, валютын зах зээлтэй холбоотой мэдээ мэдээллийг бэлтгэн
          хүргэж байна.
        </div>
      </div>
      <a href="https://www.youtube.com/@ochirootrader" alt="no-file">
        <img src={Picture} alt="no file" className="Youtube__image" />
      </a>
    </div>
  );
};

export default Youtube;

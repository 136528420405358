export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function getValueFromTree(item, pathname, key, subKey) {
  if (item.children) {
    return item.children.map((child) =>
      getValueFromTree(child, pathname, key, subKey)
    );
  } else {
    const itemPath = '/admin' + item.path;

    if (String(pathname).includes(itemPath)) {
      if (item[subKey]) {
        return item[subKey];
      } else {
        return item[key];
      }
    }
  }
}

export function getRouteFromTree(item, index, Route) {
  if (item.children) {
    return item.children.map((child) => getRouteFromTree(child, index, Route));
  } else {
    return <Route key={index} path={item.path} element={item.component} />;
  }
}

export function checkMenuOpened(item, pathname, setIsOpened) {
  if (item?.children) {
    item.children.map((child) => checkMenuOpened(child, pathname, setIsOpened));
  } else {
    const itemPath = '/admin' + item?.path;

    if (pathname === itemPath) {
      setIsOpened(true);
    }
  }
}

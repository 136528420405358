import { Link } from "react-router-dom";
import { Collapse } from "../../../ui";

import "./courseAbout.scss";

const image = require("../../../assets/aboutcourse.jpg");

const CourseAbout = () => {
  return (
    <div className="hCourseAbout">
      <div className="hCourseAbout__cover">
        <div className="hCourseAbout__cover-wall" />

        <figure className="hCourseAbout__cover-figure">
          <img src={image} alt="cover" />
        </figure>
      </div>

      <div className="hCourseAbout__content">
        <h1 className="hCourseAbout__content-title">
          Онлайн хичээлийн агуулга
        </h1>

        <div className="hCourseAbout__content-list">
          <Collapse
            title="01. Хэмжээ гэж юу вэ?"
            content="Market тэгш хэмээр хөдөлж, Цаг хугацаанд захирагддаг."
            bgColor="#ededed"
          />
          <Collapse
            title="02. Лааны цаг хугацаа гэж юу вэ?"
            content="Market тэгш хэмээр хөдөлж, Цаг хугацаанд захирагддаг."
            bgColor="#ededed"
          />
          <Collapse
            title="03. Цаг, хэмжээтэй уяалдах"
            content="Market тэгш хэмээр хөдөлж, Цаг хугацаанд захирагддаг."
            bgColor="#ededed"
          />
        </div>

        <div className="hCourseAbout__content-about">
          <h3 className="hCourseAbout__content-about-title">Онлайн Сургалт</h3>

          <p className="hCourseAbout__content-about-text">
            Байгаль дахь хууль өөрөө тэгш хэмийн тогтолцоог бий болгож гайхалтай
            давхцалаар нэгэн бүхэллэг шинжийг бүрдүүлж байдаг юм бол эдийн
            засгийн эрэлт, нийлүүлэлтийн хуулинд захирагдаж хөдөлдөг форекс зах
            зээлд тэгш хэм хэрхэн нөлөөлдөг талаар сүүлийн жилүүдэд нэлээдгүй
            судаллаа, үүний үр дүнд өөрийн гэсэн арга барилыг нээж 1$ - ийг
            31,000$ болгож өөр гайхалтай түүхүүдийг ч хийж чадсан. Өөрийн
            судалгааны үр дүнд гаргаж авсан Ochiroo’s Theory арилжааны арга
            барилаа 23 бүлэг сэдэв бүхий хичээл болгож, анхан шатнаас ахисан шат
            хүртэл онлайн сургалтаар заах болно.
          </p>

          <Link className="hCourseAbout__content-about-signup" to="/signup">
            Бүртгүүлэх
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CourseAbout;

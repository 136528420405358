import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdDeleteForever, MdOutlineMore } from 'react-icons/md';
import { useAdminContext } from '../../context/AdminContext';
import Axios from '../../Axios';
import Table from '../utils/Table/Table';
import Popup from '../../utils/Popup/Popup';

const AdminsList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { adminConfig } = useAdminContext();

  const [id, setID] = useState('');
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [visibleError, setVisibleError] = useState(false);

  const addButton = (
    <button
      className='outline-btn'
      onClick={() => navigate(pathname + '/add')}
    >
      Нэмэх
    </button>
  );

  const columns = [
    {
      Header: 'Зураг',
      accessor: 'photo',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Овог',
      accessor: 'lastname',
    },
    {
      Header: 'Нэр',
      accessor: 'firstname',
    },

    {
      Header: 'И-Мэйл',
      accessor: 'email',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Утас',
      accessor: 'phone_number',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: ' ',
      disableSortBy: true,
      disableFilters: true,
      accessor: (data) => (
        <div className='rcTable__actionsContainer'>
          <button
            key='delete'
            className='rcTable__actionsContainer-btn'
            onClick={() => {
              setID(data.id);
              setVisibleAlert(true);
            }}
          >
            <MdDeleteForever
              className='deleteBtn'
              size={30}
            />
          </button>

          <button
            key='seeDetail'
            className='rcTable__actionsContainer-btn'
            onClick={() => navigate(pathname + '/' + data.id)}
          >
            <MdOutlineMore
              className='seeDetailBtn'
              size={32}
            />
          </button>
        </div>
      ),
    },
  ];

  const alertPopupOnOK = () => {
    if (id) {
      Axios.get(`/admins/deactive/${id}`, adminConfig)
        .then((res) => {
          if (res.data.message === 'success') {
            window.location.reload();
          } else {
            setVisibleError(true);
          }
        })
        .catch(() => setVisibleError(true));
    } else {
      setVisibleError(true);
    }
  };

  const getApiSubPath = () => {
    if (String(pathname).includes('sub-admins')) {
      return 2;
    } else if (String(pathname).includes('social-admins')) {
      return 3;
    } else {
      return;
    }
  };

  return (
    <>
      <Popup
        messageType='alert'
        messageText='Та устгахдаа итгэлтэй байна уу?'
        visible={visibleAlert}
        onOk={alertPopupOnOK}
        onCancel={() => setVisibleAlert(false)}
      />

      <Popup
        messageType='sys_error'
        messageText='Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!'
        visible={visibleError}
        onOk={() => window.location.reload()}
      />

      <Table
        currentHref={pathname}
        apiPath='/admins'
        apiSubPath={getApiSubPath()}
        addButton={addButton}
        columns={columns}
        disableActions
      />
    </>
  );
};

export default AdminsList;

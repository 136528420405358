import { useEffect, useState } from "react";
import Axios from "../../../Axios";
import { Carousel, Slide } from "../../../ui/Carousel/Carousel";

import "./banner.scss";

const Banner = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get("/fopperies/1/list").then((res) => {
      if (res.status === 200) {
        let DATA = res.data;

        if (res.data.length === 1) {
          DATA = [res.data[0], res.data[0]];
        }

        setData(DATA);
      }
    });
  }, []);

  return (
    <div className="hBanner">
      <Carousel autoplaySpeed={30000} autoplay infinite>
        {Array.isArray(data) &&
          data.map((item) => (
            <Slide key={item.id}>
              <img src={item.image} alt="no file" />
            </Slide>
          ))}
      </Carousel>
    </div>
  );
};
export default Banner;

import {
  adminsRoute,
  usersRoute,
  feedbacksRoute,
  coursesRoute,
  newsRoute,
  bannersRoute,
} from './routesList';

export const ROUTES = ({ role }) => {
  const adminRoutes = [
    adminsRoute,
    usersRoute,
    feedbacksRoute,
    coursesRoute,
    newsRoute,
    bannersRoute,
  ];

  const subAdminRoutes = [
    usersRoute,
    feedbacksRoute,
    coursesRoute,
    newsRoute,
    bannersRoute,
  ];

  const socialAdminRoutes = [feedbacksRoute, newsRoute, bannersRoute];

  if (role === 1) {
    return adminRoutes;
  } else if (role === 2) {
    return subAdminRoutes;
  } else if (role === 3) {
    return socialAdminRoutes;
  }
};

import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { getRouteFromTree } from '../../../utils/Functions';
import UserForm from '../../pages/Users/UserForm';
import NewsForm from '../../pages/News/NewsForm';
import CourseForm from '../../pages/Courses/CourseForm';
import HomeCarouselForm from '../../pages/HomeCarousels/HomeCarouselForm';

import './content.scss';

const Content = ({ role }) => {
  const [defaultURL, setDefaultURL] = useState('');

  useEffect(() => {
    if (role === 1) {
      setDefaultURL('/admin/sub-admins');
    } else if (role === 2) {
      setDefaultURL('/admin/users');
    } else if (role === 3) {
      setDefaultURL('/admin/feedbacks');
    }
  }, [role]);

  const adminsPaths = useRoutes([
    { path: '/sub-admins/add', element: <UserForm /> },
    { path: '/sub-admins/:id', element: <UserForm /> },
    { path: '/social-admins/add', element: <UserForm /> },
    { path: '/social-admins/:id', element: <UserForm /> },
  ]);

  const usersPaths = useRoutes([
    { path: '/users/add', element: <UserForm /> },
    { path: '/users/:id', element: <UserForm /> },
    { path: '/premium-users/add', element: <UserForm /> },
    { path: '/premium-users/:id', element: <UserForm /> },
  ]);

  const newsPaths = useRoutes([
    { path: '/news/add', element: <NewsForm /> },
    { path: '/news/:id', element: <NewsForm /> },
  ]);

  const coursesPaths = useRoutes([
    { path: '/courses/add', element: <CourseForm /> },
    { path: '/courses/:id', element: <CourseForm /> },
    { path: '/free-courses/add', element: <CourseForm /> },
    { path: '/free-courses/:id', element: <CourseForm /> },
  ]);

  const bannersPaths = useRoutes([
    { path: '/home-carousels/add', element: <HomeCarouselForm /> },
    { path: '/home-carousels/:id', element: <HomeCarouselForm /> },
    { path: '/online-course-banners/add', element: <HomeCarouselForm /> },
    { path: '/online-course-banners/:id', element: <HomeCarouselForm /> },
  ]);

  const getRouteFromMenuItem = () => {
    return ROUTES({ role }).map((item, index) =>
      getRouteFromTree(item, index, Route)
    );
  };

  return (
    <main className='adminContent'>
      <Routes>
        <Route
          path='/'
          element={<Navigate to={defaultURL} />}
          loader={'Loading...'}
        />

        {getRouteFromMenuItem()}
      </Routes>

      {adminsPaths}
      {usersPaths}
      {newsPaths}
      {coursesPaths}
      {bannersPaths}
    </main>
  );
};

export default Content;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import moment from "moment";
import Axios from "../../Axios";
import Layout from "../../utils/Layouts/Layout/Layout";

const NewsDetail = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get(`news/${id}`).then((res) => {
      if (res.data) {
        setData(res.data);
      }
    });
  }, [id]);

  return (
    <Layout>
      <div className="newsDetail">
        <div className="newsDetail__heading">
          <h3 className="newsDetail__heading-title">
            <Link to="/news">
              <IoChevronBackCircleOutline />
            </Link>

            {data.title}
          </h3>

          <p className="newsDetail__heading-createdAt">
            {moment(data.created_at).format("YYYY.MM.DD")}
          </p>
        </div>

        <img src={data.cover} alt="no file" className="newsDetail__cover" />

        <div
          className="newsDetail__content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    </Layout>
  );
};

export default NewsDetail;

import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../utils/Layouts/Layout/Layout';
import CourseList from '../../components/CourseList/CourseList';

const Courses = () => {
  const { type } = useParams();

  let title = 'Ахисан шатны төлбөртэй сургалт';

  if (type === 'free') {
    title = 'Анхан шатны үнэгүй сургалт';
  }

  return (
    <Layout>
      <CourseList title={title} type={type} />
    </Layout>
  );
};

export default Courses;

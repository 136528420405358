import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import Axios from "../../Axios";
import Layout from "../../utils/Layouts/Layout/Layout";
import Popup from "../../utils/Popup/Popup";
import Loader from "../../utils/Loader/Loader";

const CoursePayout = () => {
  const navigate = useNavigate();

  const { id: courseID } = useParams();
  const { authConfig } = useAuthContext();

  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [popupText, setPopupText] = useState("");
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    if (courseID) {
      Axios.get(`/courses/${courseID}`).then((res) => {
        if (res.status === 200) {
          setAmount(res.data.amount);
        }
      });
    }
  }, [courseID]);

  const requestCourseHandler = () => {
    setIsLoading(true);

    Axios.post("/users_courses/request", { courseID }, authConfig)
      .then((res) => {
        if (res.data.message === "success") {
          setPopupType("success");
          setPopupText(
            "Таны хүсэлтийг хүлээн авлаа. Админ таны хүсэлтийг зөвшөөртөл түр хүлээнэ үү. Баярлалаа"
          );

          setIsLoading(false);
        } else {
          setPopupType("sys_error");
          setPopupText("Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!");
        }

        setVisiblePopup(true);
      })
      .catch(() => {
        setPopupType("sys_error");
        setPopupText("Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!");
        setVisiblePopup(true);
      });
  };

  const popupOnCancel = () => {
    if (String(popupType).includes("error")) {
      window.location.reload();
    } else {
      setVisiblePopup(false);
    }
  };

  return (
    <Layout>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => navigate(`/courses/${courseID}`)}
        onCancel={popupOnCancel}
      />

      <div className="coursePayout">
        <div className="coursePayout__header">ТӨЛБӨР ТӨЛӨХ ХЭСЭГ</div>

        <div className="coursePayout__box">
          <div className="coursePayout__box_left">
            <div className="coursePayout__box_left-item">
              <div className="coursePayout__box_left-item-1">Хичээл:</div>
              <div className="coursePayout__box_left-item-2">
                Ochiroo's Theory Форекс сургалт
              </div>
            </div>
            <div className="coursePayout__box_left-item">
              <div className="coursePayout__box_left-item-1">Үнэ</div>
              <div className="coursePayout__box_left-item-2">
                {amount.toLocaleString("en-US")}₮
              </div>
            </div>
            <div className="coursePayout__box_left-item">
              <div className="coursePayout__box_left-item-1">Дүн</div>
              <div className="coursePayout__box_left-item-2">
                {amount.toLocaleString("en-US")}₮
              </div>
            </div>
          </div>
          <div className="coursePayout__box_right">
            <div className="coursePayout__box_right_allprice">
              <div className="coursePayout__box_left-item-1">
                Дансны дугаар:
              </div>
              <div className="coursePayout__box_left-item-2">
                Хаан банк /5497 0667 30/
              </div>
            </div>
            <div className="coursePayout__box_right_allprice">
              <div className="coursePayout__box_left-item-1">
                Данс эзэмшигч:
              </div>
              <div className="coursePayout__box_left-item-2">
                Баярсайхан Пүрэв-Очир
              </div>
            </div>
            <div className="coursePayout__box_right_allprice">
              <div className="coursePayout__box_left-item-1">
                Гүйлгээний Утга:
              </div>
              <div className="coursePayout__box_left-item-2">
                Сургалтын төлбөр (Нэр + Утасны дугаар)
              </div>
            </div>
            <div className="coursePayout__box_right_text">
              -Хэрвээ та төлбөрөө шилжүүлсэн бол төлбөр шалгах товч дээр дарна
              уу.
            </div>

            {isLoading ? (
              <Loader mini />
            ) : (
              <button
                className="coursePayout__box_right-btn"
                onClick={requestCourseHandler}
              >
                Төлбөр шалгах
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CoursePayout;

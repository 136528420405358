import React from 'react';
import { RiMailFill, RiLock2Fill } from 'react-icons/ri';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAuthContext } from '../../context/AuthContext';
import { Modal, MyInput } from '../../ui';
import Loader from '../../utils/Loader/Loader';

import './forgotPassModal.scss';

const logo = require('../../assets/logo-with-bottom-name.png');

const _schemas = Yup.object().shape({
  email: Yup.string()
    .email('Зөв форматаар бичнэ үү!')
    .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, 'Зөв форматаар бичнэ үү!')
    .required('Хоосон байна!'),
  password: Yup.string()
    .min(8, 'Хамгийн багадаа 8 тэмдэгт байх ёстой!')
    .required('Хоосон байна!'),
  repassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Таарахгүй байна!')
    .required('Хоосон байна!'),
});

const ForgotPassModal = ({ visible, setVisible }) => {
  const { isLoading, recoveryHandler } = useAuthContext();

  return (
    <Modal visible={visible} onCancel={setVisible}>
      <div className='forgotPassModal'>
        <div className='forgotPassModal__heading'>
          <img
            className='forgotPassModal__heading-logo'
            src={logo}
            alt='no file'
          />

          <h3 className='forgotPassModal__heading-title'>Нууц үг сэргээх</h3>
        </div>

        <Formik
          initialValues={{ email: '', password: '', repassword: '' }}
          validationSchema={_schemas}
          onSubmit={(vals) => recoveryHandler(vals)}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            handleChange,
            handleSubmit,
          }) => (
            <form className='forgotPassModal__form' onSubmit={handleSubmit}>
              <MyInput
                icon={<RiMailFill />}
                placeholder='И-Мэйл'
                name='email'
                value={values.email}
                onChange={handleChange}
                onBlur={() => setFieldTouched('email')}
                touched={touched.email}
                errorText={errors.email}
              />

              <MyInput
                icon={<RiLock2Fill />}
                placeholder='Нууц үг'
                name='password'
                value={values.password}
                type='password'
                onChange={handleChange}
                onBlur={() => setFieldTouched('password')}
                touched={touched.password}
                errorText={errors.password}
              />

              <MyInput
                icon={<RiLock2Fill />}
                placeholder='Нууц үг давтах'
                name='repassword'
                value={values.repassword}
                type='password'
                onChange={handleChange}
                onBlur={() => setFieldTouched('repassword')}
                touched={touched.repassword}
                errorText={errors.repassword}
              />

              {isLoading ? (
                <Loader mini />
              ) : (
                <button
                  className='forgotPassModal__form-recoveryBtn'
                  type='submit'
                >
                  Сэргээх
                </button>
              )}
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ForgotPassModal;

import React from 'react';

const MyExams = () => {
  return (
    <div className='acntContent'>
      <h3 className='acntContent__heading'>Миний шалгалтууд</h3>

      <p style={{ fontSize: 20 }}>Мэдээлэл хоосон байна</p>
    </div>
  );
};

export default MyExams;

import React from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

import "./carousel.scss";

function PrevArrow(props) {
  const { onClick } = props;

  return (
    <button className="no-button carousel--cusArrow prev" onClick={onClick}>
      <FaChevronLeft
        style={{
          position: "absolute",
          bottom: "4px",
          left: "5px",
        }}
      />
    </button>
  );
}

function NextArrow(props) {
  const { onClick } = props;

  return (
    <button className="no-button carousel--cusArrow next" onClick={onClick}>
      <FaChevronRight
        style={{
          position: "absolute",
          bottom: "4px",
          left: "5px",
        }}
      />
    </button>
  );
}

export const Carousel = ({
  children,
  spaceBetween = 0,
  slideCount = 1,
  slidesToScroll = 1,
  autoplaySpeed = 4000,
  autoplay = false,
  infinite = false,
  hiddenArrows = false,
}) => {
  const settings = {
    speed: 800,
    slidesToShow: slideCount,
    slidesToScroll: slidesToScroll,
    autoplaySpeed: autoplaySpeed,
    infinite: infinite,
    autoplay: autoplay,
    arrows: !hiddenArrows,
    pauseOnHover: false,
    touchMove: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 912,
        settings: {
          slidesToShow: slideCount > 1 ? 2 : 1,
          slidesToScroll: slidesToScroll > 1 ? 2 : 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel" style={{ marginInline: spaceBetween * 0 }}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export const Slide = ({ children, spaceBetween = 0 }) => {
  return (
    <div className="carousel__slide" style={{ paddingInline: spaceBetween }}>
      {children}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import {
  RiMenuUnfoldFill,
  RiUser3Line,
  RiShieldKeyholeLine,
  RiFileCopy2Line,
  RiCompasses2Fill,
  RiFileHistoryLine,
} from 'react-icons/ri';
import Axios from '../../Axios';
import { useAuthContext } from '../../context/AuthContext';
import Layout from '../../utils/Layouts/Layout/Layout';
import Popup from '../../utils/Popup/Popup';
import MenuDrawer from '../../components/Account/MenuDrawer/MenuDrawer';
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import MyCourses from './MyCourses';
import MyExams from './MyExams';
import MyOrderHistory from './MyOrderHistory';

const noFile = require('../../assets/no-file.png');

const Account = () => {
  const { authConfig, logoutHandler } = useAuthContext();

  const [userData, setUserData] = useState({});
  const [alertVisible, setAlertVisible] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  useEffect(() => {
    if (authConfig) {
      Axios.get('/auth/me', authConfig).then((res) => {
        if (res.status === 200) {
          setUserData(res.data);
        }
      });
    }
  }, [authConfig]);

  const getUserRole = () => {
    if (userData?.role_id) {
      let role = '';

      if (userData.role_id === 4) {
        role = 'Энгийн';
      } else if (userData.role_id === 5) {
        role = 'Ахисан';
      }

      return role;
    }
  };

  const renderMenu = (inToggle) => {
    return (
      <div
        className={`account__menu-nav ${
          inToggle && 'account__menu-nav--toggle'
        }`}
      >
        <NavLink
          className={(navData) =>
            `account__menu-nav-item ${navData.isActive && 'active'} 
            ${navData.isActive && inToggle && 'active--toggle'}`
          }
          to='/account/profile'
          onClick={() => setShowToggle(false)}
        >
          <RiUser3Line />
          <b>Хувийн мэдээлэл</b>
        </NavLink>

        <NavLink
          className={(navData) =>
            `account__menu-nav-item ${navData.isActive && 'active'} 
            ${navData.isActive && inToggle && 'active--toggle'}`
          }
          to='/account/change-password'
          onClick={() => setShowToggle(false)}
        >
          <RiShieldKeyholeLine />
          <b>Нууц үг солих</b>
        </NavLink>

        <NavLink
          className={(navData) =>
            `account__menu-nav-item ${navData.isActive && 'active'} 
            ${navData.isActive && inToggle && 'active--toggle'}`
          }
          to='/account/my-courses'
          onClick={() => setShowToggle(false)}
        >
          <RiFileCopy2Line />
          <b>Миний хичээлүүд</b>
        </NavLink>

        <NavLink
          className={(navData) =>
            `account__menu-nav-item ${navData.isActive && 'active'} 
            ${navData.isActive && inToggle && 'active--toggle'}`
          }
          to='/account/my-exams'
          onClick={() => setShowToggle(false)}
        >
          <RiCompasses2Fill />
          <b>Миний шалгалтууд</b>
        </NavLink>

        <NavLink
          className={(navData) =>
            `account__menu-nav-item ${navData.isActive && 'active'} 
            ${navData.isActive && inToggle && 'active--toggle'}`
          }
          to='/account/order-history'
          onClick={() => setShowToggle(false)}
        >
          <RiFileHistoryLine />
          <b>Миний захиалгын түүх</b>
        </NavLink>

        <button
          className='account__menu-nav-signoutBtn'
          onClick={() => setAlertVisible(true)}
        >
          <b>Системээс гарах</b>
        </button>
      </div>
    );
  };

  return (
    <Layout>
      <Popup
        visible={alertVisible}
        messageType='alert'
        messageText='Та гарахдаа итгэлтэй байна уу?'
        onOk={logoutHandler}
        onCancel={() => setAlertVisible(false)}
      />

      <div className='account'>
        <div className='account--drawerToggle'>
          <button
            className='account--drawerToggle-btn'
            onClick={() => setShowToggle(true)}
          >
            <RiMenuUnfoldFill />
          </button>

          <MenuDrawer
            visible={showToggle}
            renderMenu={renderMenu}
            onCancel={() => setShowToggle(false)}
          />
        </div>

        <div className='account__menu'>
          <div className='account__menu-heading'>
            <img
              className='account__menu-heading-avatar'
              src={userData?.avatar}
              alt='no file'
              onError={(event) => {
                event.target.onerror = null;
                event.target.src = noFile;
              }}
            />

            <div className='account__menu-heading-tag'>
              <label className='account__menu-heading-tag-label'>
                Хэрэглэгчийн нэр:
              </label>
              {userData?.firstname && userData?.lastname && (
                <p className='account__menu-heading-tag-text'>
                  {String(userData.lastname).charAt(0) +
                    '. ' +
                    userData.firstname}
                </p>
              )}

              <label className='account__menu-heading-tag-label'>
                Эрхийн төрөл:
              </label>
              <p className='account__menu-heading-tag-text'>{getUserRole()}</p>
            </div>
          </div>

          {renderMenu()}
        </div>

        <div className='account__content'>
          <Routes>
            <Route path='/' element={<Navigate to='/account/profile' />} />

            <Route path='/profile' element={<Profile />} />
            <Route path='/change-password' element={<ChangePassword />} />
            <Route path='/my-courses' element={<MyCourses />} />
            <Route path='/my-exams' element={<MyExams />} />
            <Route path='/order-history' element={<MyOrderHistory />} />
          </Routes>
        </div>
      </div>
    </Layout>
  );
};

export default Account;

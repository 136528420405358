import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AdminProvider } from './context/AdminContext';
import { AuthProvider } from './context/AuthContext';
import App from './App';
import ScrollToTop from './utils/ScrollToTop';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AdminProvider>
      <AuthProvider>
        <ScrollToTop />
        <App />
      </AuthProvider>
    </AdminProvider>
  </BrowserRouter>
);

import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import { checkMenuOpened } from '../../../utils/Functions';

const MenuItem = ({ item }) => {
  const { pathname } = useLocation();

  const [isOpened, setIsOpened] = useState(false);

  const isMenuOpened = useCallback(
    (val) => {
      if (val.children) {
        return val.children.map((child) => isMenuOpened(child));
      } else {
        if (pathname === val.path) {
          setIsOpened(true);
        }
      }
    },
    [pathname]
  );

  useEffect(() => {
    checkMenuOpened(item, pathname, setIsOpened);
  }, [item, pathname]);

  function iconChecker(icon, label) {
    let res = <b>{String(label).substring(0, 2)}</b>;

    if (icon) {
      res = icon;
    }

    return res;
  }

  if (item.children) {
    return (
      <div className={`menu__item ${isOpened && 'opened'}`}>
        <div
          className={`menu__item-label ${isOpened && 'opened'}`}
          onClick={() => setIsOpened((val) => !val)}
        >
          <div className='menu__item-label-title'>
            <span className='menu__item-icon'>
              {iconChecker(item.icon, item.label)}
            </span>
            <span className='menu__item-name'>{item.label}</span>
          </div>

          <BsChevronRight
            className={`menu__item-arrow ${isOpened && 'opened'}`}
          />
        </div>

        <div className={`menu__item-content ${isOpened && 'opened'}`}>
          {item.children.map((child, index) => (
            <MenuItem key={index} item={child} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        className={({ isActive }) => `menu__item plain ${isActive && 'active'}`}
        to={`/admin${item.path}`}
      >
        <span className='menu__item-icon'>
          {iconChecker(item.icon, item.label)}
        </span>
        <span className='menu__item-name'>{item.label}</span>
      </NavLink>
    );
  }
};

export default MenuItem;

import { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FaCircleUser } from 'react-icons/fa6';
import { TbPhoneCall, TbMail, TbMenu2 } from 'react-icons/tb';
import Marquee from 'react-fast-marquee';
import { useAuthContext } from '../../context/AuthContext';
import Axios from '../../Axios';
import LoginModal from '../../modals/LoginModal/LoginModal';

import './header.scss';

const logo = require('../../assets/logo-with-bottom-name.png');
const logo_white = require('../../assets/logo-white.png');

const Header = ({ onHome = false }) => {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { isLogin } = useAuthContext();

  const [text, setText] = useState('');
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleLogin, setVisibleLogin] = useState(false);

  useEffect(() => {
    const menuHandler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setVisibleMenu(false);
      }
    };

    document.addEventListener('click', menuHandler);

    return () => document.removeEventListener('click', menuHandler);
  }, []);

  useEffect(() => {
    Axios.get('/fopperies/2/list').then((res) => {
      if (res?.data.length > 0 && res.data[0].text) {
        setText(res.data[0].text);
      } else {
        setText('');
      }
    });
  }, []);

  return (
    <header className={`header ${onHome ? '' : 'noneHome'}`}>
      <LoginModal
        visible={visibleLogin}
        setVisible={setVisibleLogin}
      />

      {onHome && (
        <div className='header__top'>
          <div className='header__top-content'>
            <ul className='header__top-content-contacts'>
              <li className='header__top-content-contacts-item'>
                <TbPhoneCall className='header__top-content-contacts-item-icon' />

                <div className='header__top-content-contacts-item-info'>
                  <span>Холбоо барих</span>
                  <b>80804949</b>
                </div>
              </li>

              <li className='header__top-content-contacts-item'>
                <TbMail className='header__top-content-contacts-item-icon' />

                <div className='header__top-content-contacts-item-info'>
                  <span>Тусламж</span>
                  <b>support@iotb.com</b>
                </div>
              </li>
            </ul>

            {text && (
              <p className='header__top-content-text'>
                <Marquee
                  speed={30}
                  gradient={false}
                >
                  {text}
                </Marquee>
              </p>
            )}
          </div>
        </div>
      )}

      <nav className={`header__navbar ${onHome ? '' : 'on_other_page'}`}>
        <div className='header__navbar-content'>
          <div className='header__navbar-content--box'>
            <Link
              className='header__navbar-content-logoLink'
              to='/'
            >
              <img
                src={onHome ? logo : logo_white}
                alt='no file'
              />
            </Link>

            <button
              ref={menuRef}
              className='header__navbar-content-mblMenuBtn'
              onClick={() => setVisibleMenu((prev) => !prev)}
            >
              <TbMenu2 />
            </button>
          </div>

          <ul
            className={`header__navbar-content-links ${
              visibleMenu ? 'shown' : 'hidden'
            }`}
          >
            <NavLink
              className={({ isActive }) => (isActive ? 'active_link' : '')}
              to='/courses'
            >
              Онлайн сургалт
            </NavLink>

            <NavLink
              className={({ isActive }) => (isActive ? 'active_link' : '')}
              to='/mentorship'
            >
              Менторшип
            </NavLink>

            <NavLink
              className={({ isActive }) => (isActive ? 'active_link' : '')}
              to='/news'
            >
              Мэдээ мэдээлэл
            </NavLink>

            <NavLink
              className={({ isActive }) => (isActive ? 'active_link' : '')}
              to='/channels'
            >
              Сувгууд
            </NavLink>

            <NavLink
              className={({ isActive }) => (isActive ? 'active_link' : '')}
              to='/scholarship'
            >
              Тэтгэлэг
            </NavLink>

            <NavLink
              className={({ isActive }) => (isActive ? 'active_link' : '')}
              to='/contact'
            >
              Холбоо барих
            </NavLink>
          </ul>

          {isLogin ? (
            <Link to='/account'>
              <FaCircleUser
                size={35}
                color={onHome ? 'blue' : 'white'}
                style={{ marginBottom: -4 }}
              />
            </Link>
          ) : (
            <div className='header__navbar-content-authBtns'>
              <button
                className={`outline-btn ${onHome ? '' : 'blue_signBtn'}`}
                onClick={() => navigate('/signup')}
              >
                Бүртгүүлэх
              </button>

              <button
                className={onHome ? '' : 'white_loginBtn'}
                onClick={() => setVisibleLogin(true)}
              >
                Нэвтрэх
              </button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;

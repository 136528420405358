import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAdminContext } from '../../../context/AdminContext';
import Axios from '../../../Axios';
import MyInput from '../../../ui/MyInput/MyInput';
import Loader from '../../../utils/Loader/Loader';
import Card from '../../utils/Card/Card';
import Popup from '../../../utils/Popup/Popup';
import FileUploader from '../../utils/FileUploader/FileUploader';

const UserForm = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const { adminConfig } = useAdminContext();

  const [data, setData] = useState({});
  const [imageFile, setImageFile] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [btnIsLoading, setBtnIsLoading] = useState(false);

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    if (id) {
      Axios.get(`/users/${id}`, adminConfig)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);

          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        });
    } else {
      setIsLoading(false);
      setData({});
    }
  }, [id, adminConfig]);

  const schema = Yup.object().shape({
    firstname: Yup.string()
      .matches(/^[A-Z][a-z0-9_-]*$/, 'Эхний үсэг том байх ёстой!')
      .required('Хоосон байна!'),
    lastname: Yup.string()
      .matches(/^[A-Z][a-z0-9_-]*$/, 'Эхний үсэг том байх ёстой!')
      .required('Хоосон байна!'),
    phone_number: Yup.string()
      .matches(/^[0-9]*$/, 'Зөвхөн тоо бичнэ үү!')
      .min(8, 'Урт багадаа 8 байх ёстой!')
      .max(8, '8-аас их байж болохгүй!')
      .required('Хоосон байна!'),
    email: id
      ? ''
      : Yup.string()
          .email('Зөв форматаар бичнэ үү!')
          .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, 'Зөв форматаар бичнэ үү!')
          .required('Хоосон байна!'),
  });

  const createHandler = (ROLE_ID, values, formData) => {
    formData.append('roleID', ROLE_ID);
    formData.append('username', values.username);
    formData.append('firstname', values.firstname);
    formData.append('lastname', values.lastname);
    formData.append('phone_number', values.phone_number);
    formData.append('email', values.email);
    formData.append('password', values.phone_number);
    formData.append('avatar', data.avatar);
    formData.append('imageFile', imageFile);

    Axios.post('/users', formData, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          const _path = String(pathname).replace('/add', '');

          window.location.replace(_path);
        } else {
          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('');
        setVisiblePopup(true);
      });
  };

  const editHandler = (values, formData) => {
    formData.append('id', id);
    formData.append('username', values.username);
    formData.append('firstname', values.firstname);
    formData.append('lastname', values.lastname);
    formData.append('phone_number', values.phone_number);
    formData.append('avatar', data.avatar);
    formData.append('imageFile', imageFile);

    Axios.put('/users', formData, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          window.location.reload();
        } else {
          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('');
        setVisiblePopup(true);
      });
  };

  const saveHandler = (values) => {
    setBtnIsLoading(true);

    const formData = new FormData();

    if (id) {
      editHandler(values, formData);
    } else {
      let ROLE_ID;

      if (String(pathname).includes('sub-admins')) {
        ROLE_ID = 2;
      } else if (String(pathname).includes('social-admins')) {
        ROLE_ID = 3;
      } else if (String(pathname).includes('premium-user')) {
        ROLE_ID = 5;
      } else {
        ROLE_ID = 4;
      }

      createHandler(ROLE_ID, values, formData);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card centered>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => window.location.reload()}
      />

      <Formik
        initialValues={{
          firstname: data.firstname,
          lastname: data.lastname,
          phone_number: data.phone_number,
          username: data.username,
        }}
        validationSchema={schema}
        onSubmit={(vals) => saveHandler(vals)}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleSubmit,
        }) => (
          <form className='myForm' onSubmit={handleSubmit}>
            <span className='myForm__row'>
              <label className='myForm__row-label'>Зураг:</label>

              <FileUploader file={data.avatar} getFile={setImageFile} />
            </span>

            <span className='myForm__row'>
              <label className='myForm__row-label'>Хэрэглэгчийн нэр:</label>
              <MyInput
                name='username'
                value={values.username}
                onChange={handleChange}
                onBlur={setFieldTouched}
                touched={touched.username}
                errorText={errors.username}
              />
            </span>

            <span className='myForm__row'>
              <label className='myForm__row-label'>Овог:</label>
              <MyInput
                name='lastname'
                value={values.lastname}
                onChange={handleChange}
                onBlur={setFieldTouched}
                touched={touched.lastname}
                errorText={errors.lastname}
              />
            </span>

            <span className='myForm__row'>
              <label className='myForm__row-label'>Нэр:</label>
              <MyInput
                name='firstname'
                value={values.firstname}
                onChange={handleChange}
                onBlur={setFieldTouched}
                touched={touched.firstname}
                errorText={errors.firstname}
              />
            </span>

            <span className='myForm__row'>
              <label className='myForm__row-label'>Утасны дугаар:</label>
              <MyInput
                name='phone_number'
                value={values.phone_number}
                onChange={handleChange}
                onBlur={setFieldTouched}
                touched={touched.phone_number}
                errorText={errors.phone_number}
              />
            </span>

            {id ? null : (
              <span className='myForm__row'>
                <label className='myForm__row-label'>И-Мэйл:</label>
                <MyInput
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={setFieldTouched}
                  touched={touched.email}
                  errorText={errors.email}
                />
              </span>
            )}

            {btnIsLoading ? (
              <Loader mini />
            ) : (
              <button type='submit'>Хадгалах</button>
            )}
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default UserForm;

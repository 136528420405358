import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiMailFill, RiLock2Fill } from 'react-icons/ri';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAuthContext } from '../../context/AuthContext';
import { Checkbox, Modal, MyInput } from '../../ui';
import Loader from '../../utils/Loader/Loader';
import ForgotPassModal from '../ForgotPassModal/ForgotPassModal';

import './loginModal.scss';

const logo = require('../../assets/logo-with-bottom-name.png');
const man = require('../../assets/businessman.png');

const schemas = Yup.object().shape({
  email: Yup.string()
    .email('Зөв форматаар бичнэ үү!')
    .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, 'Зөв форматаар бичнэ үү!')
    .required('Хоосон байна!'),
  password: Yup.string()
    .min(8, 'Хамгийн багадаа 8 тэмдэгт байх ёстой!')
    .required('Хоосон байна!'),
});

const LoginModal = ({ visible, setVisible }) => {
  const { isLoading, loginHandler } = useAuthContext();

  const REMEMBER_KEY = 'REMEMBER_PASS';

  const [saved, setSaved] = useState(null);
  const [visibleForgot, setVisibleForgot] = useState(false);

  useEffect(() => {
    let _saved = null;

    if (localStorage.getItem(REMEMBER_KEY)) {
      _saved = JSON.parse(localStorage.getItem(REMEMBER_KEY)).values;
    }

    setSaved(_saved);
  }, [saved]);

  const rememberPassOnChange = (e, { values }) => {
    const isChecked = e.target?.checked;

    if (isChecked) {
      localStorage.setItem(REMEMBER_KEY, JSON.stringify({ values }));
    } else {
      localStorage.removeItem(REMEMBER_KEY);
    }
  };

  return (
    <Modal visible={visible} onCancel={setVisible}>
      <ForgotPassModal visible={visibleForgot} setVisible={setVisibleForgot} />

      <div className='loginModal'>
        {visibleForgot || (
          <img className='loginModal--man' src={man} alt='no file' />
        )}

        <div className='loginModal__heading'>
          <span className='loginModal__heading-text'>
            <h3>Нэвтрэх</h3>
            <p>Welcome to IOTB forex academy</p>
          </span>

          <img className='loginModal__heading-logo' src={logo} alt='no file' />
        </div>

        <Formik
          initialValues={{
            email: saved?.email ? saved.email : '',
            password: saved?.password ? saved.password : '',
          }}
          validationSchema={schemas}
          onSubmit={(vals) => loginHandler(vals)}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            handleChange,
            handleSubmit,
          }) => (
            <form className='loginModal__form' onSubmit={handleSubmit}>
              <MyInput
                icon={<RiMailFill />}
                placeholder='И-Мэйл'
                name='email'
                value={values.email}
                onChange={handleChange}
                onBlur={() => setFieldTouched('email')}
                touched={touched.email}
                errorText={errors.email}
              />

              <MyInput
                icon={<RiLock2Fill />}
                placeholder='Нууц үг'
                name='password'
                value={values.password}
                type='password'
                onChange={handleChange}
                onBlur={() => setFieldTouched('password')}
                touched={touched.password}
                errorText={errors.password}
              />

              <span className='loginModal__form-help'>
                <Checkbox
                  checked={saved}
                  onChange={(e) => rememberPassOnChange(e, { values })}
                >
                  Сануулах
                </Checkbox>

                <button
                  type='button'
                  className='no-button'
                  onClick={() => setVisibleForgot(true)}
                >
                  Нууц үгээ мартсан уу?
                </button>
              </span>

              {isLoading ? (
                <Loader mini />
              ) : (
                <button type='submit'>Нэвтрэх</button>
              )}
            </form>
          )}
        </Formik>

        <p className='loginModal__signup'>
          Бүртгэлгүй юу?
          <Link to='/signup'>Бүртгүүлэх</Link>
        </p>
      </div>
    </Modal>
  );
};

export default LoginModal;

import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import Axios from '../../../Axios';
import { Carousel, Slide } from '../../../ui/Carousel/Carousel';

import './freeCourse.scss';

const noFile = require('../../../assets/no-file.png');
const noIMG = require('../../../assets/no-image.png');

const FreeCourse = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get('/courses/1/list').then((res) => {
      if (res.status === 200 && res.data?.length > 0) {
        setData(res.data.slice(0, 6));
      }
    });
  }, []);

  /* ======== Item Height ======================================================= */
  const itemRef = useRef(null);
  const [itemHeight, setItemHeight] = useState(0);

  useEffect(() => {
    if (itemRef.current && data?.length > 0) {
      setItemHeight(itemRef.current.getBoundingClientRect().height);
    }
  }, [itemRef, data]);
  /* ============================================================================ */

  return (
    <div className='hFreeCourse'>
      <h1 className='hFreeCourse__title'>Үнэгүй сургалт</h1>

      <Carousel spaceBetween={20} slideCount={3} autoplay infinite>
        {Array.isArray(data) &&
          data.map((item, idx) => (
            <Slide key={idx} spaceBetween={20}>
              <div ref={itemRef} className='hFreeCourse__item'>
                <figure className='hFreeCourse__item-cover'>
                  <img
                    src={item.cover ? item.cover : noIMG}
                    alt='no file'
                    onError={(event) => {
                      event.target.onerror = null;
                      event.target.src = noFile;
                    }}
                  />
                </figure>

                <div className='hFreeCourse__item-content'>
                  <h3 className='hFreeCourse__item-content-title'>
                    {item.title}
                  </h3>

                  <p className='hFreeCourse__item-content-desc'>
                    <TextTruncate
                      text={
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              String(item.content).length > 300
                                ? item.content.substring(0, 300) + '...'
                                : item.content,
                          }}
                        />
                      }
                      line={itemHeight > 320 ? 10 : 6}
                    />
                  </p>

                  <Link
                    className='hFreeCourse__item-content-detail'
                    to={`/courses/${item.id}`}
                  >
                    {'Дэлгэрэнгүй >>'}
                  </Link>
                </div>
              </div>
            </Slide>
          ))}
      </Carousel>
    </div>
  );
};

export default FreeCourse;

import { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MyInput } from '../../ui';
import LoginModal from '../../modals/LoginModal/LoginModal';

const logo = require('../../assets/logo.png');

const _schemas = Yup.object().shape({
  firstname: Yup.string().required('Хоосон байна!'),
  lastname: Yup.string().required('Хоосон байна!'),
  phonenumber: Yup.string()
    .matches(/^[0-9]*$/, 'Зөвхөн тоо бичнэ үү!')
    .min(8, 'Урт багадаа 8 байх ёстой!')
    .max(8, '8-аас их байж болохгүй!')
    .required('Хоосон байна!'),
  email: Yup.string()
    .email('Зөв форматаар бичнэ үү!')
    .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, 'Зөв форматаар бичнэ үү!')
    .required('Хоосон байна!'),
  citizenship: Yup.string().required('Хоосон байна!'),
  password: Yup.string()
    .min(8, 'Хамгийн багадаа 8 тэмдэгт байх ёстой!')
    .required('Хоосон байна!'),
  repassword: Yup.string()
    .min(8, 'Хамгийн багадаа 8 тэмдэгт байх ёстой!')
    .oneOf([Yup.ref('password')], 'Таарахгүй байна!')
    .required('Хоосон байна!'),
});

const ScholarshipDetail = () => {
  const [visibleLogin, setVisibleLogin] = useState(false);

  const scholarshipHandler = (values) => {
    console.log('Signup values:', values);
  };

  return (
    <main className='scholarshipDetail'>
      <LoginModal visible={visibleLogin} setVisible={setVisibleLogin} />

      {/* <img
        className='scholarshipDetail__cover'
        src={Photo}
        alt='no file'
      /> */}

      <div className='scholarshipDetail__content'>
        <a className='scholarshipDetail__content-goBack' href='/scholarship'>
          <IoIosArrowBack />
          <label>Буцах</label>
        </a>

        <img
          className='scholarshipDetail__content-logo'
          src={logo}
          alt='no file'
        />

        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            phonenumber: '',
            email: '',
            citizenship: '',
            password: '',
            repassword: '',
          }}
          validationSchema={_schemas}
          onSubmit={(vals) => scholarshipHandler(vals)}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            handleChange,
            handleSubmit,
          }) => (
            <form
              className='scholarshipDetail__content-form'
              onSubmit={handleSubmit}
            >
              <span className='scholarshipDetail__content-form-row'>
                <MyInput
                  label='Овог'
                  name='lastname'
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('lastname')}
                  touched={touched.lastname}
                  errorText={errors.lastname}
                />

                <MyInput
                  label='Нэр'
                  name='firstname'
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('firstname')}
                  touched={touched.firstname}
                  errorText={errors.firstname}
                />
              </span>

              <MyInput
                label='Утасны дугаар'
                name='phonenumber'
                value={values.phonenumber}
                onChange={handleChange}
                onBlur={() => setFieldTouched('phonenumber')}
                touched={touched.phonenumber}
                errorText={errors.phonenumber}
              />

              <MyInput
                label='И-мэйл хаяг'
                name='email'
                value={values.email}
                onChange={handleChange}
                onBlur={() => setFieldTouched('email')}
                touched={touched.email}
                errorText={errors.email}
              />

              <MyInput
                label='Иргэншил'
                name='citizenship'
                value={values.citizenship}
                onChange={handleChange}
                onBlur={() => setFieldTouched('citizenship')}
                touched={touched.citizenship}
                errorText={errors.citizenship}
              />

              <MyInput
                label='Нууц үг'
                name='password'
                value={values.password}
                type='password'
                onChange={handleChange}
                onBlur={() => setFieldTouched('password')}
                touched={touched.password}
                errorText={errors.password}
              />

              <MyInput
                label='Нууц үг давтах'
                name='repassword'
                value={values.repassword}
                type='password'
                onChange={handleChange}
                onBlur={() => setFieldTouched('repassword')}
                touched={touched.repassword}
                errorText={errors.repassword}
              />

              <div className='scholarshipDetail__content-form-checkText'>
                {/* <Checkbox
                  id='readTos'
                  name='readTos'
                  onChange={(e) =>
                    console.log(`${e.target.name}:`, e.target.checked)
                  }
                >
                  Би 18 нас хүрсэн бөгөөд <b>үйлчилгээний нөхцөл</b>-тэй
                  танилцсан.
                </Checkbox>

                <Checkbox
                  id='readPrivacy'
                  name='readPrivacy'
                  onChange={(e) =>
                    console.log(`${e.target.name}:`, e.target.checked)
                  }
                >
                  <b>Нууцлалын бодлого</b>-той уншиж танилцсан.
                </Checkbox> */}
              </div>

              <button type='submit'>Бүртгүүлэх</button>
            </form>
          )}
        </Formik>

        {/* <div className='scholarshipDetail__content-bottom'>
          Бүртгэлтэй хэрэглэгч үү?
          <button
            className='no-button scholarship__content-bottom-loginBtn'
            type='button'
            onClick={() => setVisibleLogin(true)}
          >
            Нэвтрэх
          </button>
        </div> */}
      </div>
    </main>
  );
};

export default ScholarshipDetail;

import React, { useEffect, useRef, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';

import './collapse.scss';

const Collapse = ({ title, content, bgColor = '' }) => {
  const contentRef = useRef(null);

  const [contentHeight, setContentHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  // const [isRotated, setIsRotated] = useState(false);

  useEffect(() => {
    setContentHeight(contentRef.current.offsetHeight);
  }, []);

  return (
    <div className='collapse' style={{ background: bgColor }}>
      <button
        className='no-button collapse__title'
        onClick={() => setVisible((prev) => !prev)}
      >
        {title}
        {<FaArrowRight style={{ transform: 'rotate(0deg)' }} />}
      </button>

      <div
        className='collapse__body'
        style={{ height: visible ? contentHeight : 0 }}
      >
        <div ref={contentRef} className='collapse__body-content'>
          {content}
        </div>
      </div>
    </div>
  );
};

export default Collapse;

import React from 'react';
import moment from 'moment';
import Table from '../utils/Table/Table';

const Feedbacks = () => {
  const columns = [
    {
      Header: 'Нэр',
      accessor: 'name',
    },
    {
      Header: 'Имэйл',
      accessor: 'email',
    },
    {
      Header: 'Санал хүсэлтүүд',
      disableFilters: true,
      accessor: 'feedback',
    },
    {
      Header: 'Ирсэн огноо',
      accessor: 'created_at',
      disableFilters: true,
      Cell: ({ value }) => (
        <p style={{ width: 140 }}>{moment(value).format('YYYY/MM/DD')}</p>
      ),
    },
  ];

  return <Table apiPath='/feedbacks' columns={columns} disableActions />;
};

export default Feedbacks;

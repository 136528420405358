import React from "react";
import Picture from "../../assets/telegram.png";

import "./telegram.scss";

const Youtube = () => {
  return (
    <div className="Telegram">
      <div className="Telegram__box">
        <div className="Telegram__box__title">Ochiroo's Theory </div>
        <div className="Telegram__box__text">
          Арилжаа сонирхдог, арилжаа хийдэг хүмүүстээ Ochiroo's Theory сувгаар
          дамжуулан форекс, валютын зах зээлтэй холбоотой мэдээ мэдээллийг
          бэлтгэн хүргэж байна.
        </div>
      </div>
      <a href="https://t.me/ochirootrade" alt="no-file">
        <img src={Picture} alt="no file" className="Telegram__image" />
      </a>
    </div>
  );
};

export default Youtube;

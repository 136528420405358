import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAdminContext } from '../../../context/AdminContext';
import Axios from '../../../Axios';
import Loader from '../../../utils/Loader/Loader';
import Card from '../../utils/Card/Card';
import Popup from '../../../utils/Popup/Popup';
import FileUploader from '../../utils/FileUploader/FileUploader';

const HomeCarouselForm = () => {
  const { id } = useParams();
  const { adminConfig } = useAdminContext();

  const [data, setData] = useState({});
  const [imageFile, setImageFile] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [btnIsLoading, setBtnIsLoading] = useState(false);

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    if (id) {
      Axios.get(`/fopperies/${id}`, adminConfig)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);

          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        });
    } else {
      setIsLoading(false);
      setData({});
    }
  }, [id, adminConfig]);

  const createHandler = (formData) => {
    formData.append('imageFile', imageFile);

    Axios.post('/fopperies/banner', formData, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          window.location.replace('/admin/home-carousels');
        } else {
          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('');
        setVisiblePopup(true);
      });
  };

  const editHandler = (formData) => {
    formData.append('id', id);
    formData.append('image', data.image);
    formData.append('imageFile', imageFile);

    Axios.put('/fopperies/banner', formData, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          window.location.reload();
        } else {
          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('');
        setVisiblePopup(true);
      });
  };

  const saveHandler = () => {
    setBtnIsLoading(true);

    const formData = new FormData();

    if (id) {
      editHandler(formData);
    } else {
      createHandler(formData);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card centered>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => window.location.reload()}
      />

      <FileUploader file={data.image} getFile={setImageFile} />

      <center style={{ marginTop: 10 }}>
        {btnIsLoading ? (
          <Loader mini />
        ) : (
          <button onClick={saveHandler}>Хадгалах</button>
        )}
      </center>
    </Card>
  );
};

export default HomeCarouselForm;

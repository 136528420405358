import "./partners.scss";

const deriv = require("../../../assets/Logo-1.png");
const eightcap = require("../../../assets/Logo-2.png");
const xmbroker = require("../../../assets/Logo-4.png");
const megafunded = require("../../../assets/Logo-3.png");

const Partners = () => {
  return (
    <div className="hPartners">
      <p className="hPartners__title">Хамтрагч Байгууллага</p>

      <span className="hPartners__logos">
        <a
          href="https://track.deriv.com/_5qZAcUJaqJE9F-13urvbiWNd7ZgqdRLk/1/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={deriv} alt="no file" />
        </a>

        <a
          href="https://join.eightcap.com/visit/?bta=42184&brand=eightcap"
          target="_blank"
          rel="noreferrer"
        >
          <img src={eightcap} alt="no file" />
        </a>

        <a
          href="https://shop.getmegafunded.com/?aff=8"
          target="_blank"
          rel="noreferrer"
        >
          <img src={megafunded} alt="no file" />
        </a>

        <a
          href="https://clicks.pipaffiliates.com/c?c=849639&l=en&p=1"
          target="_blank"
          rel="noreferrer"
        >
          <img src={xmbroker} alt="no file" />
        </a>
      </span>
    </div>
  );
};

export default Partners;

import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import Axios from "../../Axios";

import "./courseList.scss";

const noFile = require("../../assets/no-file.png");
const noIMG = require("../../assets/no-image.png");
const banner = require("../../assets/header.jpg");

const CourseList = ({
  title = "Хичээлүүд",
  type, // type is only advanced and free
  inUserCourses = false,
}) => {
  const { authConfig } = useAuthContext();

  const [data, setData] = useState([]);

  const getAllCourses = useCallback(() => {
    let typeID = 0;

    if (type === "free") {
      typeID = 1;
    }

    Axios.get(`/courses/${typeID}/list`).then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  }, [type]);

  const getUserCourses = useCallback(() => {
    Axios.get("/users_courses/user", authConfig).then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  }, [authConfig]);

  useEffect(() => {
    if (inUserCourses) {
      getUserCourses();
    } else {
      getAllCourses();
    }
  }, [inUserCourses, getUserCourses, getAllCourses]);

  return (
    <div className="courseList">
      {inUserCourses || (
        <img className="courseList__cover" src={banner} alt="no file" />
      )}

      {inUserCourses || (
        <div className="courseList__heading">
          <b className="courseList__heading-title">{title}</b>

          <span className="courseList__heading-sort">
            Ангилал:
            <select>
              <option>Нийт</option>
            </select>
          </span>
        </div>
      )}

      <div className="courseList__courses">
        {Array.isArray(data) &&
          data.map((item) => (
            <div key={item.id} className="courseList__courses-item">
              <Link className="detailText" to={`/courses/${item.id}`}>
                {"Дэлгэрэнгүй >>"}
              </Link>

              <figure className="courseList__courses-item-cover">
                <img
                  src={item?.cover ? item.cover : noIMG}
                  alt="no file"
                  onError={(event) => {
                    event.target.onerror = null;
                    event.target.src = noFile;
                  }}
                />
              </figure>

              <div className="courseList__courses-item-content">
                <b className="courseList__courses-item-content-title">
                  {item.title}
                </b>

                <div
                  className="courseList__courses-item-content-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      String(item.description).length > 300
                        ? item.description.substring(0, 300) + "..."
                        : item.description,
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CourseList;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './card.scss';

const Card = ({ children, goBackURL = '', centered = false }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className={`adnCard ${centered && 'centered'}`}>
      <h3 className='adnCard__heading'>
        <button
          className='adnCard__heading-goBack'
          onClick={() => (goBackURL ? navigate(goBackURL) : navigate(-1))}
        >
          Буцах
        </button>

        <label className='adnCard__heading-title'>
          {String(pathname).includes('/add') ? 'Нэмэх хэсэг' : 'Засах хэсэг'}
        </label>
      </h3>

      <div className='adnCard__content'>{children}</div>
    </div>
  );
};

export default Card;

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAdminContext } from '../../../context/AdminContext';
import Axios from '../../../Axios';
import MyInput from '../../../ui/MyInput/MyInput';
import Loader from '../../../utils/Loader/Loader';
import Card from '../../utils/Card/Card';
import Popup from '../../../utils/Popup/Popup';
import FileUploader from '../../utils/FileUploader/FileUploader';
import CKeditor from '../../utils/Ckeditor';
import LessonsModal from '../../components/CourseLessons/LessonsModal';
import AddLessonModal from '../../components/CourseLessons/AddLessonModal';

const schema = Yup.object().shape({
  title: Yup.string().required('Хоосон байна!'),
  amount: Yup.string().required('Хоосон байна!'),
  description: Yup.string().required('Хоосон байна!'),
});

const CourseForm = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { adminConfig } = useAdminContext();

  const [data, setData] = useState({});
  const [coverFile, setCoverFile] = useState();
  // const [introFile, setIntroFile] = useState();
  const [visibleAddLesson, setVisibleAddLesson] = useState(false);
  const [visibleLessons, setVisibleLessons] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [btnIsLoading, setBtnIsLoading] = useState(false);

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  let reloadURL = '/admin/courses';

  if (String(pathname).includes('free-course')) {
    reloadURL = '/admin/free-courses';
  }

  useEffect(() => {
    if (id) {
      Axios.get(`/courses/${id}`, adminConfig)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);

          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        });
    } else {
      setIsLoading(false);
      setData({});
    }
  }, [id, adminConfig]);

  const createHandler = (values, formData, isFree) => {
    formData.append('creatorID', 'ec71b784-69bf-4d58-bb77-e5c8dddacdd7');
    formData.append('categoryID', 1);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('isFree', isFree);
    formData.append('amount', values.amount);
    formData.append('cover', data.cover);
    formData.append('imageFile', coverFile);
    // formData.append('intro_video', data.intro_video);
    // formData.append('videoFile', introFile);

    Axios.post('/courses', formData, adminConfig)
      .then((res) => {
        if (res.data.message === 'success' && res.data?.courseID) {
          window.location.replace(reloadURL + `/${res.data.courseID}`);
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        setVisiblePopup(true);
      });
  };

  const editHandler = (values, formData, isFree) => {
    formData.append('id', id);
    formData.append('creatorID', 'ec71b784-69bf-4d58-bb77-e5c8dddacdd7');
    formData.append('categoryID', 1);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('isFree', isFree);
    formData.append('amount', values.amount);
    formData.append('cover', data.cover);
    formData.append('imageFile', coverFile);
    // formData.append('intro_video', data.intro_video);
    // formData.append('videoFile', introFile);

    Axios.put('/courses', formData, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          window.location.reload();
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        setVisiblePopup(true);
      });
  };

  const onSubmit = (values) => {
    setBtnIsLoading(true);

    const formData = new FormData();

    let isFree = false;

    if (String(pathname).includes('free-course')) {
      isFree = true;
    }

    if (id) {
      editHandler(values, formData, isFree);
    } else {
      createHandler(values, formData, isFree);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card goBackURL={reloadURL} centered>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => window.location.reload()}
      />

      <AddLessonModal
        visible={visibleAddLesson}
        courseID={id}
        onCancel={() => setVisibleAddLesson(false)}
      />

      <LessonsModal
        visible={visibleLessons}
        courseID={id}
        showAddLesson={() => setVisibleAddLesson(true)}
        onCancel={() => setVisibleLessons(false)}
      />

      <Formik
        initialValues={{
          title: data.title,
          amount: data.amount,
          description: data.description,
        }}
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleSubmit,
        }) => (
          <form className='myForm' onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className='myForm__row'>
                <label className='myForm__row-label'>Ковер:</label>

                <FileUploader file={data.cover} getFile={setCoverFile} />
              </span>

              {/* <span className='myForm__row'>
                <label className='myForm__row-label'>Танилцуулга бичлэг:</label>

                <FileUploader
                  file={data.intro_video}
                  getFile={setIntroFile}
                  video
                />
              </span> */}

              {id && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    gap: 6,
                  }}
                >
                  <button
                    style={{ height: 'fit-content', fontSize: 14 }}
                    type='button'
                    onClick={() => setVisibleLessons(true)}
                  >
                    Хичээлүүд харах
                  </button>

                  <button
                    style={{
                      height: 'fit-content',
                      background: 'green',
                      borderColor: 'green',
                      fontSize: 14,
                    }}
                    type='button'
                    onClick={() => setVisibleAddLesson(true)}
                  >
                    Хичээл нэмэх
                  </button>
                </div>
              )}
            </div>

            <span className='myForm__row'>
              <label className='myForm__row-label'>Гарчиг:</label>
              <MyInput
                name='title'
                value={values.title}
                onChange={handleChange}
                onBlur={setFieldTouched}
                touched={touched.title}
                errorText={errors.title}
              />
            </span>

            {data.is_free || (
              <span className='myForm__row'>
                <label className='myForm__row-label'>Үнэ:</label>
                <MyInput
                  name='amount'
                  value={values.amount}
                  type='number'
                  onChange={handleChange}
                  onBlur={setFieldTouched}
                  touched={touched.amount}
                  errorText={errors.amount}
                />
              </span>
            )}

            <span className='myForm__row'>
              <label className='myForm__row-label'>Тайлбар:</label>

              <CKeditor
                onFormik
                name='description'
                setText={setFieldValue}
                text={values.description}
                small
              />
            </span>

            {btnIsLoading ? (
              <Loader mini />
            ) : (
              <button type='submit'>Хадгалах</button>
            )}
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default CourseForm;

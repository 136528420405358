import React from 'react';
import moment from 'moment';
import Table from '../../admin/utils/Table/Table';

const MyOrderHistory = () => {
  const columns = [
    {
      Header: 'Сургалтын гарчиг',
      accessor: 'course_title',
    },
    {
      Header: 'Ирсэн огноо',
      accessor: 'created_at',
      disableFilters: true,
      Cell: ({ value }) => (
        <p style={{ width: 160 }}>{moment(value).format('YYYY/MM/DD HH:mm')}</p>
      ),
    },
  ];

  return (
    <div className='acntContent'>
      <h3 className='acntContent__heading'>Миний захиалгын түүх</h3>

      <div style={{ width: '100%' }}>
        <Table
          apiPath='/users_courses'
          apiSubPath='requests/user'
          columns={columns}
          rowCount={5}
          useUserConfig
          disableActions
        />
      </div>
    </div>
  );
};

export default MyOrderHistory;

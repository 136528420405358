import React from 'react';
import { Link } from 'react-router-dom';
import { RiLock2Fill, RiUser3Fill } from 'react-icons/ri';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAdminContext } from '../../context/AdminContext';
import { MyInput } from '../../ui';
import Loader from '../../utils/Loader/Loader';

const logo = require('../../assets/logo-with-bottom-name.png');

const _schemas = Yup.object().shape({
  username: Yup.string().required('Хоосон байна!'),
  password: Yup.string()
    .min(8, 'Хамгийн багадаа 8 тэмдэгт байх ёстой!')
    .required('Хоосон байна!'),
});

const AdminLogin = () => {
  const { isLoading, loginHandler } = useAdminContext();

  return (
    <main className='adminLogin'>
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={_schemas}
        onSubmit={(vals) => loginHandler(vals)}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleSubmit,
        }) => (
          <form className='adminLogin__card' onSubmit={handleSubmit}>
            <Link to='/'>
              <img className='logo' src={logo} alt='no file' />
            </Link>

            <MyInput
              icon={<RiUser3Fill />}
              name='username'
              placeholder='Хэрэглэгчийн нэр/И-Мэйл'
              value={values.username}
              onChange={handleChange}
              onBlur={() => setFieldTouched('username')}
              touched={touched.username}
              errorText={errors.username}
            />

            <MyInput
              icon={<RiLock2Fill />}
              name='password'
              placeholder='Нууц үг'
              value={values.password}
              type='password'
              onChange={handleChange}
              onBlur={() => setFieldTouched('password')}
              touched={touched.password}
              errorText={errors.password}
            />

            {isLoading ? (
              <Loader mini />
            ) : (
              <button type='submit'>Нэвтрэх</button>
            )}
          </form>
        )}
      </Formik>
    </main>
  );
};

export default AdminLogin;

import { useEffect, useState } from 'react';
import { RenderImageUploader } from './RenderImageUploader';
import { RenderVideoUploader } from './RenderVideoUploader';

import './fileUploader.scss';

const noImage = require('../../../assets/no-image.png');
const noVideo = require('../../../assets/no-image.png');

const FileUploader = ({
  file,
  getFile,
  imgWidth,
  avatar = false,
  video = false,
  center = false,
}) => {
  const [inputID, setInputID] = useState('');

  const [currentFile, setCurrentFile] = useState(noImage);
  const [haveFile, setHaveFile] = useState(false);

  useEffect(() => {
    setInputID(Math.random());

    if (file) {
      setHaveFile(true);
      setCurrentFile(file);
    } else {
      setHaveFile(false);

      if (video) {
        setCurrentFile(noVideo);
      } else {
        setCurrentFile(noImage);
      }
    }
  }, [file, video]);

  const handleFileChanger = (e) => {
    let fileReader = new FileReader();
    let uploadingFile = e.target.files[0];

    fileReader.onloadend = () => {
      setHaveFile(true);

      getFile(uploadingFile);
      setCurrentFile(fileReader.result);
    };

    if (uploadingFile) {
      fileReader.readAsDataURL(uploadingFile);
    }
  };

  if (video) {
    return RenderVideoUploader({
      center,
      inputID,
      currentFile,
      haveFile,
      handleFileChanger,
    });
  } else {
    return RenderImageUploader({
      avatar,
      center,
      inputID,
      currentFile,
      haveFile,
      imgWidth,
      handleFileChanger,
    });
  }
};

export default FileUploader;

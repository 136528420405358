import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAdminContext } from '../../../context/AdminContext';
import Axios from '../../../Axios';
import Modal from '../../../ui/Modal/Modal';
import Popup from '../../../utils/Popup/Popup';
import Loader from '../../../utils/Loader/Loader';
import MyInput from '../../../ui/MyInput/MyInput';
// import FileUploader from '../../utils/FileUploader/FileUploader';

const schema = Yup.object().shape({
  title: Yup.string().required('Хоосон байна!'),
  video_link: Yup.string().required('Хоосон байна!'),
});

const AddLessonModal = ({ visible, courseID, onCancel }) => {
  const { adminConfig } = useAdminContext();

  // const [videoFile, setVideoFile] = useState();
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  const saveHandler = (values) => {
    setBtnIsLoading(true);

    // const formData = new FormData();

    // formData.append('courseID', courseID);
    // formData.append('title', values.title);
    // formData.append('videoFile', videoFile);

    const startLenght = String(values.video_link).indexOf(' width');
    const linkStart = String(values.video_link).slice(0, startLenght);
    const endLenght = String(values.video_link).indexOf('frameborder');
    const linkEnd = String(values.video_link).slice(endLenght);

    const link = linkStart + ' width="100%" height="100%" ' + linkEnd;

    const DATA = {
      courseID,
      title: values.title,
      video: link,
    };

    Axios.post('/course_lessons', DATA, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          setPopupType('success');
          setPopupText('Амжилттай хадгалагдлаа');
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        }

        setVisiblePopup(true);
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        setVisiblePopup(true);
      });
  };

  return (
    <Modal visible={visible} onCancel={onCancel}>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => window.location.reload()}
      />

      <Formik
        initialValues={{ title: '', video_link: '' }}
        validationSchema={schema}
        onSubmit={(values) => saveHandler(values)}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleSubmit,
        }) => (
          <form className='myForm' onSubmit={handleSubmit}>
            <span className='myForm__row'>
              <label className='myForm__row-label'>Гарчиг:</label>
              <MyInput
                name='title'
                value={values.title}
                onChange={handleChange}
                onBlur={setFieldTouched}
                touched={touched.title}
                errorText={errors.title}
              />
            </span>

            <span className='myForm__row'>
              <label className='myForm__row-label'>
                Хичээлийн бичлэгний линк:
              </label>

              {/* <FileUploader getFile={setVideoFile} center video /> */}
              <MyInput
                name='video_link'
                value={values.video_link}
                onChange={handleChange}
                onBlur={setFieldTouched}
                touched={touched.video_link}
                errorText={errors.video_link}
              />
            </span>

            {btnIsLoading ? (
              <Loader mini />
            ) : (
              // <button type='submit' disabled={!videoFile}>
              //   {videoFile ? 'Хадгалах' : 'Хичээлийн бичлэгийг оруулна уу!'}
              // </button>
              <button type='submit' disabled={!values.video_link}>
                {values.video_link
                  ? 'Хадгалах'
                  : 'Хичээлийн бичлэгийг оруулна уу!'}
              </button>
            )}
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddLessonModal;

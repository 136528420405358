import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from '../Axios';
import Popup from '../utils/Popup/Popup';

const AdminContext = createContext();

export const useAdminContext = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
  const ITEM_KEY = 'ADMIN_TOKEN';

  const navite = useNavigate();

  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adminConfig, setAdminConfig] = useState(null);

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem(ITEM_KEY);

    if (token) {
      setIsLogin(true);
      setAdminConfig({ headers: { token } });
    } else {
      setIsLogin(false);
      setAdminConfig(null);
    }
  }, []);

  function loginHandler({ username, password }) {
    setIsLoading(true);

    Axios.post('/admins/login', { username, password })
      .then((res) => {
        if (res.data) {
          const { token } = res.data;
          localStorage.setItem(ITEM_KEY, token);

          setIsLogin(true);
          navite();

          setAdminConfig({ headers: { token } });
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
          setVisiblePopup(true);
        }
      })
      .finally(() => setIsLoading(false))
      .catch((err) => {
        if (err.response.status === 409) {
          setPopupType('error');
          setPopupText(err.response.data.message);
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        }

        setVisiblePopup(true);
      });
  }

  const logoutHandler = () => {
    localStorage.removeItem(ITEM_KEY);
    setIsLogin(false);

    navite('/admin');
  };

  const popupOnOK = () => {
    if (popupType === 'error') {
      setIsLoading(false);
      setVisiblePopup(false);
    } else {
      window.location.reload();
    }
  };

  return (
    <AdminContext.Provider
      value={{
        isLoading,
        isLogin,
        adminConfig,
        loginHandler,
        logoutHandler,
      }}
    >
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={popupOnOK}
      />

      {children}
    </AdminContext.Provider>
  );
};

import { LuMail, LuPhone, LuInstagram } from 'react-icons/lu';

import './homeContact.scss';

const HomeContact = () => {
  return (
    <div className='homeContact'>
      <h3 className='homeContact__title'>Бидэнтэй холбогдохыг хүсвэл:</h3>

      <div className='homeContact__list'>
        <div className='homeContact__list-items'>
          <span className='homeContact__list-items-icon'>
            <LuMail />
          </span>
          otheory.mn@gmail.com
        </div>

        <div className='homeContact__list-items'>
          <span className='homeContact__list-items-icon'>
            <LuPhone />
          </span>
          +976 80804949
        </div>

        <a
          className='homeContact__list-items'
          href='https://www.instagram.com/ochiroo.ot'
          target='_blank'
          rel='noreferrer'
        >
          <span className='homeContact__list-items-icon'>
            <LuInstagram />
          </span>
          Ochiroo_trader
        </a>
      </div>
    </div>
  );
};

export default HomeContact;

import React, { useEffect, useState } from 'react';
import { useAdminContext } from '../../context/AdminContext';
import Axios from '../../Axios';
import Loader from '../../utils/Loader/Loader';
import Card from '../utils/Card/Card';
import Popup from '../../utils/Popup/Popup';
import { MyInput } from '../../ui';

const HeaderText = () => {
  const { adminConfig } = useAdminContext();

  const [id, setID] = useState('');
  const [text, setText] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [btnIsLoading, setBtnIsLoading] = useState(false);

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    Axios.get('/fopperies/2/list')
      .then((res) => {
        setID(res.data[0].id);
        setText(res.data[0].text);

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);

        setPopupType('sys_error');
        setPopupText('');
        setVisiblePopup(true);
      });
  }, []);

  const saveHandler = () => {
    setBtnIsLoading(true);

    Axios.put('/fopperies/header-text', { id, text }, adminConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          window.location.reload();
        } else {
          setPopupType('sys_error');
          setPopupText('');
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('');
        setVisiblePopup(true);
      });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card centered>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => window.location.reload()}
      />

      <div style={{ width: 400 }}>
        <MyInput
          name='text'
          value={text}
          isTextarea
          rows={3}
          onChange={(e) => setText(e.target.value)}
        />
      </div>

      <center style={{ marginTop: 10 }}>
        {btnIsLoading ? (
          <Loader mini />
        ) : (
          <button onClick={saveHandler}>Хадгалах</button>
        )}
      </center>
    </Card>
  );
};

export default HeaderText;

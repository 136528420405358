import { useEffect, useState } from 'react';
import { RiLock2Fill, RiSave2Fill } from 'react-icons/ri';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from '../../Axios';
import { useAuthContext } from '../../context/AuthContext';
import { MyInput } from '../../ui';
import Loader from '../../utils/Loader/Loader';
import Popup from '../../utils/Popup/Popup';

const schema = Yup.object().shape({
  old_password: Yup.string()
    .min(8, 'Хамгийн багадаа 8 тэмдэгт байх ёстой!')
    .required('Хоосон байна!'),
  new_password: Yup.string()
    .min(8, 'Хамгийн багадаа 8 тэмдэгт байх ёстой!')
    .required('Хоосон байна!'),
  repassword: Yup.string()
    .oneOf([Yup.ref('new_password')], 'Таарахгүй байна!')
    .required('Хоосон байна!'),
});

const ChangePassword = () => {
  const { authConfig, loginHandler } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    if (authConfig) {
      Axios.get('/auth/me', authConfig).then((res) => {
        if (res.status === 200) {
          setEmail(res.data.email);
        }
      });
    }
  }, [authConfig]);

  const saveHandler = (values) => {
    setIsLoading(true);

    const DATA = {
      email,
      oldPassword: values.old_password,
      newPassword: values.new_password,
    };

    Axios.put('/users/change-password', DATA, authConfig)
      .then((res) => {
        if (res.data.message === 'success') {
          setPopupType('success');
          setPopupText('Таны нууц үг амжилттай солигдлоо.');

          setNewPassword(values.new_password);
        } else {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        }

        setVisiblePopup(true);
      })
      .catch(() => {
        setPopupType('sys_error');
        setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
        setVisiblePopup(true);
      });
  };

  const popupOnOK = () => {
    if (popupType === 'sys_error') {
      window.location.reload();
    } else {
      loginHandler({
        email,
        password: newPassword,
        doReload: true,
      });
    }
  };

  return (
    <div className='acntContent'>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={popupOnOK}
      />

      <h3 className='acntContent__heading'>Нууц үг солих</h3>

      <Formik
        initialValues={{
          old_password: '',
          new_password: '',
          repassword: '',
        }}
        validationSchema={schema}
        onSubmit={(values) => saveHandler(values)}
      >
        {({
          values,
          errors,
          touched,
          setFieldTouched,
          handleChange,
          handleSubmit,
        }) => (
          <form className='acntContent__wrapper' onSubmit={handleSubmit}>
            <div className='acntContent__wrapper-box'>
              <div className='acntContent__wrapper-box-input'>
                <b className='acntContent__wrapper-box-input-label'>
                  Хуучин нууц үг
                </b>
                <MyInput
                  icon={<RiLock2Fill />}
                  name='old_password'
                  value={values.old_password}
                  type='password'
                  onChange={handleChange}
                  onBlur={setFieldTouched}
                  touched={touched.old_password}
                  errorText={errors.old_password}
                />
              </div>

              <div className='acntContent__wrapper-box-input'>
                <b className='acntContent__wrapper-box-input-label'>
                  Шинэ нууц үг
                </b>
                <MyInput
                  icon={<RiLock2Fill />}
                  name='new_password'
                  value={values.new_password}
                  type='password'
                  onChange={handleChange}
                  onBlur={setFieldTouched}
                  touched={touched.new_password}
                  errorText={errors.new_password}
                />
              </div>

              <div className='acntContent__wrapper-box-input'>
                <b className='acntContent__wrapper-box-input-label'>
                  Шинэ нууц үг давтах
                </b>
                <MyInput
                  icon={<RiLock2Fill />}
                  name='repassword'
                  value={values.repassword}
                  type='password'
                  onChange={handleChange}
                  onBlur={setFieldTouched}
                  touched={touched.repassword}
                  errorText={errors.repassword}
                />
              </div>
            </div>

            {isLoading ? (
              <Loader mini />
            ) : (
              <button className='acntContent__saveBtn' type='submit'>
                <RiSave2Fill /> Хадгалах
              </button>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;

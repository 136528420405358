import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../../utils/Table/Table';

const Users = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const addButton = (
    <button className='outline-btn' onClick={() => navigate(pathname + '/add')}>
      Нэмэх
    </button>
  );

  const columns = [
    {
      Header: 'Зураг',
      accessor: 'photo',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Овог',
      accessor: 'lastname',
    },
    {
      Header: 'Нэр',
      accessor: 'firstname',
    },

    {
      Header: 'И-Мэйл',
      accessor: 'email',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Утас',
      accessor: 'phone_number',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const getApiSubPath = () => {
    if (String(pathname).includes('sub-admins')) {
      return 2;
    } else if (String(pathname).includes('social-admins')) {
      return 3;
    } else if (String(pathname).includes('premium-user')) {
      return 5;
    } else {
      return 4;
    }
  };

  return (
    <Table
      currentHref={pathname}
      apiPath='/users'
      apiSubPath={getApiSubPath()}
      addButton={addButton}
      columns={columns}
    />
  );
};

export default Users;

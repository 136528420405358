import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Table from '../../utils/Table/Table';

const News = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const addButton = (
    <button className='outline-btn' onClick={() => navigate(pathname + '/add')}>
      Нэмэх
    </button>
  );

  const columns = [
    {
      Header: 'Гарчиг',
      accessor: 'title',
    },
    {
      Header: 'Ангилал',
      accessor: 'category',
    },
    {
      Header: 'Нийтэлсэн огноо',
      accessor: 'created_at',
      Cell: ({ value }) => moment(value).format('YYYY/MM/DD'),
    },
  ];

  return (
    <Table
      currentHref={pathname}
      apiPath='/news'
      addButton={addButton}
      columns={columns}
    />
  );
};

export default News;

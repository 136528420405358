import './checkbox.scss';

const Checkbox = ({
  id = '',
  name = '',
  checked,
  value,
  onChange,
  children,
}) => {
  const HTML_FOR = `${id}__html_for`;

  return (
    <div className='checkbox'>
      <input
        type='checkbox'
        id={HTML_FOR}
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={HTML_FOR}>{children}</label>
    </div>
  );
};

export default Checkbox;

import React, { useState } from "react";
import { FaPhoneAlt, FaLocationArrow, FaClock } from "react-icons/fa";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "../Axios";
import { MyInput } from "../ui";
import Layout from "../utils/Layouts/Layout/Layout";
import Popup from "../utils/Popup/Popup";

const schema = Yup.object().shape({
  name: Yup.string().required("Хоосон байна!"),
  email: Yup.string()
    .email("Зөв форматаар бичнэ үү!")
    .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Зөв форматаар бичнэ үү!")
    .required("Хоосон байна!"),
  feedback: Yup.string().required("Хоосон байна!"),
});

const Contact = () => {
  const [popupType, setPopupType] = useState("");
  const [popupText, setPopupText] = useState("");
  const [visiblePopup, setVisiblePopup] = useState(false);

  const sendHandler = (values, { resetForm }) => {
    Axios.post("/feedbacks", values)
      .then((res) => {
        if (res.data.message === "success") {
          setPopupType("success");
          setPopupText("Таны хүсэлт амжилттай илгээгдлээ.");
          setVisiblePopup(true);

          resetForm();
        } else {
          setPopupType("sys_error");
          setPopupText("");
          setVisiblePopup(true);
        }
      })
      .catch(() => {
        setPopupType("sys_error");
        setPopupText("");
        setVisiblePopup(true);
      });
  };

  return (
    <Layout>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => setVisiblePopup(false)}
      />

      <div className="contact">
        <div className="contact__wrapper">
          <ul className="contact__wrapper-info">
            <li className="contact__wrapper-info-item">
              <span className="contact__wrapper-info-item-label">
                <FaPhoneAlt />
                Утасны дугаар
              </span>

              <span className="contact__wrapper-info-item-val">
                +976 8080-4949
              </span>
            </li>

            <li className="contact__wrapper-info-item">
              <span className="contact__wrapper-info-item-label">
                <FaLocationArrow />
                Хаяг
              </span>

              <span className="contact__wrapper-info-item-val">
                support@iotb.com
              </span>
            </li>

            <li className="contact__wrapper-info-item">
              <span className="contact__wrapper-info-item-label">
                <FaClock />
                Цагийн хуваарь
              </span>

              <span className="contact__wrapper-info-item-val">
                Ажлын өдрүүдэд 9:00 - 17:00-ын хооронд ажиллана.
              </span>
            </li>
          </ul>

          <Formik
            initialValues={{
              name: "",
              email: "",
              feedback: "",
            }}
            validationSchema={schema}
            onSubmit={(values, { resetForm }) =>
              sendHandler(values, { resetForm })
            }
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleSubmit,
            }) => (
              <form
                className="contact__wrapper-feedback"
                onSubmit={handleSubmit}
              >
                <h3 className="contact__wrapper-feedback-title">
                  Санал хүсэлт
                </h3>

                <MyInput
                  name="name"
                  placeholder="Нэр"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={setFieldTouched}
                  touched={touched.name}
                  errorText={errors.name}
                />

                <MyInput
                  name="email"
                  placeholder="Имэйл хаяг"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={setFieldTouched}
                  touched={touched.email}
                  errorText={errors.email}
                />

                <div className="myInput">
                  <textarea
                    name="feedback"
                    rows={4}
                    cols={50}
                    value={values.feedback}
                    placeholder="Санал хүсэлт"
                    onChange={handleChange}
                    onBlur={setFieldTouched}
                    touched={touched.feedback}
                    errorText={errors.feedback}
                  />

                  {errors.feedback && (
                    <label className="myInput__errorText">
                      {errors.feedback}
                    </label>
                  )}
                </div>

                <button type="submit">Илгээх</button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

import { Link } from "react-router-dom";
import Layout from "../../utils/Layouts/Layout/Layout";

const advanced = require("../../assets/advanced.jpg");
const beginner = require("../../assets/beginner.jpg");

const CourseTypes = () => {
  return (
    <Layout>
      <div className="courseTypes">
        <h3 className="courseTypes__title">Сургалтын төрөл</h3>

        <div className="courseTypes__types">
          <Link className="courseTypes__types-item" to="/courses/advanced/list">
            <span className="seeText">{"<< үзэх >>"}</span>

            <figure className="courseTypes__types-item-cover">
              <img src={advanced} alt="no file" />
            </figure>

            <div className="courseTypes__types-item-content">
              <b className="courseTypes__types-item-content-title">
                Ахисан шатны төлбөртэй сургалтууд
              </b>

              <p>
                Ochiroo's Theory техник шинжилгээг үзсэнээр Маркет тэгш хэмээр
                хөдөлж, Цаг хугацаанд захирагддаг зарчмыг ойлгох болно.
              </p>
            </div>
          </Link>

          <Link className="courseTypes__types-item" to="/courses/free/list">
            <span className="seeText">{"<< үзэх >>"}</span>

            <figure className="courseTypes__types-item-cover">
              <img src={beginner} alt="no file" />
            </figure>

            <div className="courseTypes__types-item-content">
              <b className="courseTypes__types-item-content-title">
                Анхан шатны үнэгүй сургалтууд
              </b>

              <p>
                Санхүүгийн зах зээлийн талаар анхан болон дунд шатны мэдлэгийг
                үнэ төлбөргүй үзэх боломжтой.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default CourseTypes;

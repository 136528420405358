import AdminsList from '../pages/AdminsList';
import Users from '../pages/Users';
import Feedbacks from '../pages/Feedbacks';
import Courses from '../pages/Courses';
import CourseRequests from '../pages/RequestsAndPayeds/CourseRequests';
import ApprovedCourses from '../pages/RequestsAndPayeds/ApprovedCourses';
import News from '../pages/News';
import HomeCarousels from '../pages/HomeCarousels';
import HeaderText from '../pages/HeaderText';

export const adminsRoute = {
  // icon: <RiUserFill />,
  label: 'Админууд',
  children: [
    {
      // icon: <RiPieChart2Line />,
      label: 'Дэд админууд',
      heading: 'Дэд админууд',
      path: '/sub-admins',
      component: <AdminsList />,
    },
    {
      // icon: <RiPieChart2Line />,
      label: 'Сошиал админууд',
      heading: 'Сошиал админууд',
      path: '/social-admins',
      component: <AdminsList />,
    },
  ],
};

export const usersRoute = {
  label: 'Хэрэглэгч',
  children: [
    {
      label: 'Энгийн',
      heading: 'Энгийн хэрэглэгч',
      path: '/users',
      component: <Users />,
    },
    {
      label: 'Ахисан',
      heading: 'Ахисан хэрэглэгч',
      path: '/premium-users',
      component: <Users />,
    },
  ],
};

export const feedbacksRoute = {
  label: 'Санал хүсэлтүүд',
  heading: 'Санал хүсэлтүүд',
  path: '/feedbacks',
  component: <Feedbacks />,
};

export const coursesRoute = {
  label: 'Сургалтууд',
  children: [
    {
      label: 'Төлбөртэй',
      heading: 'Төлбөртэй сургалт',
      path: '/courses',
      component: <Courses />,
    },
    {
      label: 'Үнэгүй',
      heading: 'Үнэгүй сургалт',
      path: '/free-courses',
      component: <Courses />,
    },
    {
      label: 'Хүсэлт',
      heading: 'Сургалтын хүсэлтүүд',
      path: '/course-requests',
      component: <CourseRequests />,
    },
    {
      label: 'Зөвшөөрсөн',
      heading: 'Зөвшөөрсөн сургалтууд',
      path: '/payed-courses',
      component: <ApprovedCourses />,
    },
  ],
};

export const newsRoute = {
  label: 'Мэдээ',
  heading: 'Мэдээ, Мэдээлэл',
  path: '/news',
  component: <News />,
};

export const bannersRoute = {
  label: 'Бусад',
  children: [
    {
      label: 'Нүүрний коверууд',
      heading: 'Нүүрний коверууд',
      path: '/home-carousels',
      component: <HomeCarousels />,
    },
    {
      label: 'Толгойн текст',
      heading: 'Толгойн текст',
      path: '/header-text',
      component: <HeaderText />,
    },
  ],
};

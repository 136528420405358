import React, { useEffect, useState } from 'react';
import {
  RiUserLine,
  RiPhoneFill,
  RiMailLine,
  RiSave2Fill,
} from 'react-icons/ri';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from '../../Axios';
import { useAuthContext } from '../../context/AuthContext';
import { MyInput } from '../../ui';
import FileUploader from '../../admin/utils/FileUploader/FileUploader';
import Popup from '../../utils/Popup/Popup';
import Loader from '../../utils/Loader/Loader';

const schema = Yup.object().shape({
  firstname: Yup.string().required('Хоосон байна!'),
  lastname: Yup.string().required('Хоосон байна!'),
  phone_number: Yup.string().required('Хоосон байна!'),
});

const Profile = () => {
  const { authConfig } = useAuthContext();

  const [userData, setUserData] = useState({});
  const [imageFile, setImageFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [popupType, setPopupType] = useState('');
  const [popupText, setPopupText] = useState('');
  const [visiblePopup, setVisiblePopup] = useState(false);

  useEffect(() => {
    if (authConfig) {
      Axios.get('/auth/me', authConfig).then((res) => {
        if (res.status === 200) {
          setUserData(res.data);
        }
      });
    }
  }, [authConfig]);

  const saveHandler = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append('id', userData.id);
    formData.append('firstname', values.firstname);
    formData.append('lastname', values.lastname);
    formData.append('phone_number', values.phone_number);
    formData.append('avatar', userData.avatar);
    formData.append('imageFile', imageFile);

    if (authConfig) {
      Axios.put('/users', formData, authConfig)
        .then((res) => {
          if (res.data.message === 'success') {
            setPopupType('success');
            setPopupText('Амжилттай хадгалагдлаа');
          } else {
            setPopupType('sys_error');
            setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
          }

          setVisiblePopup(true);
        })
        .catch(() => {
          setPopupType('sys_error');
          setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
          setVisiblePopup(true);
        });
    } else {
      setPopupType('sys_error');
      setPopupText('Ямар нэгэн алдаа гарлаа. Та дахин оролдоно уу!');
      setVisiblePopup(true);
    }
  };

  return (
    <div className='acntContent'>
      <Popup
        messageType={popupType}
        messageText={popupText}
        visible={visiblePopup}
        onOk={() => window.location.reload()}
      />

      <h3 className='acntContent__heading'>Хэрэглэгчийн мэдээлэл</h3>

      {Object.keys(userData).length > 0 && (
        <Formik
          initialValues={{
            firstname: userData.firstname,
            lastname: userData.lastname,
            phone_number: userData.phone_number,
            email: userData.email,
          }}
          validationSchema={schema}
          onSubmit={(values) => saveHandler(values)}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            handleChange,
            handleSubmit,
          }) => (
            <form className='acntContent__wrapper' onSubmit={handleSubmit}>
              <FileUploader
                file={userData.avatar}
                getFile={setImageFile}
                center
                avatar
              />

              <div className='acntContent__wrapper-box boxWithIMG'>
                <div className='acntContent__wrapper-box-input'>
                  <b className='acntContent__wrapper-box-input-label'>Овог</b>
                  <MyInput
                    icon={<RiUserLine />}
                    name='lastname'
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={setFieldTouched}
                    touched={touched.lastname}
                    errorText={errors.lastname}
                  />
                </div>

                <div className='acntContent__wrapper-box-input'>
                  <b className='acntContent__wrapper-box-input-label'>Нэр</b>
                  <MyInput
                    icon={<RiUserLine />}
                    name='firstname'
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={setFieldTouched}
                    touched={touched.firstname}
                    errorText={errors.firstname}
                  />
                </div>

                <div className='acntContent__wrapper-box-input'>
                  <b className='acntContent__wrapper-box-input-label'>
                    Утасны дугаар
                  </b>
                  <MyInput
                    icon={<RiPhoneFill />}
                    name='phone_number'
                    value={values.phone_number}
                    onChange={handleChange}
                    onBlur={setFieldTouched}
                    touched={touched.phone_number}
                    errorText={errors.phone_number}
                  />
                </div>

                <div className='acntContent__wrapper-box-input'>
                  <b className='acntContent__wrapper-box-input-label'>Имэйл</b>

                  <MyInput
                    icon={<RiMailLine />}
                    value={values.email}
                    disabled
                  />
                </div>
              </div>

              {isLoading ? (
                <Loader mini />
              ) : (
                <button className='acntContent__saveBtn' type='submit'>
                  <RiSave2Fill /> Хадгалах
                </button>
              )}
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default Profile;

import React, { useRef } from 'react';

import './menuDrawer.scss';

const MenuDrawer = ({ visible, renderMenu, onCancel }) => {
  const drawerRef = useRef();

  const closeOnClick = (e) => {
    if (onCancel && drawerRef.current === e.target) {
      onCancel();
    }
  };

  return visible ? (
    <div className='menuDrawer' ref={drawerRef} onClick={closeOnClick}>
      <div className='menuDrawer__content'>{renderMenu(true)}</div>
    </div>
  ) : null;
};

export default MenuDrawer;

import './footer.scss';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__content'>
        <h3 className='footer__content-title'>Эрсдлийн санамж</h3>

        <personalbar className='footer__content-text'>
          Манай академи нь тусгай зөвшөөрөлтэй санхүүгийн болон арилжааны
          зөвлөгөө өгдөг байгууллага биш бөгөөд Форекс, Валютын зах зээлтэй
          холбоотой, мэдлэг боловсрол олгох чиг үүрэгтэй байгууллага болно. Хувь
          хүн арилжаа хийгээд олсон орлого, алдсан алдагдал зэрэгт хуулийн
          хариуцлага хүлээхгүй болно. Арилжаанд орох гарах шийдвэрийг хувь хүн
          өөрийн сайн дурын үндсэн дээр бусад этгээдээс үл хамааран шийдвэрлэнэ.
        </personalbar>

        <b className='footer__content-copyright'>
          Бүх эрх хуулиар хамгаалагдсан &copy;{1900 + new Date().getYear()}
        </b>
      </div>
    </footer>
  );
};

export default Footer;

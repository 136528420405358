import { Carousel, Slide } from "../../../ui/Carousel/Carousel";
import Picture1 from "../../../assets/comment1.jpg";
import Picture2 from "../../../assets/comment2.jpg";
import Picture3 from "../../../assets/comment3.jpg";
import Picture4 from "../../../assets/comment4.png";
import "./comments.scss";

const noFile = require("../../../assets/no-file.png");

const Comments = () => {
  return (
    <div className="homeComments">
      <Carousel
        spaceBetween={20}
        slideCount={3}
        autoplaySpeed={10000}
        autoplay
        infinite
      >
        <Slide spaceBetween={20}>
          <div className="homeComments__box">
            <figure className="homeComments-avatar">
              <img
                src={Picture4}
                alt="no file"
                onError={(event) => {
                  event.target.onerror = null;
                  event.target.src = noFile;
                }}
              />
            </figure>

            <div className="homeComments__box__text">
              Монголчууд бидэнд гэрэл асаасанд талархаж байна. Монгол хүнээ
              гэсэн сэтгэлийн гал, хүч зориг чинь биднийг өдөөлөө. Замыг нь
              гэрэлтүүлээд өгөхөд дагаад алхаад байж дээ. Буян чинь бороо мэт
              асгарах болтугай. Дахин дахин баярлалаа.
            </div>
            <div className="homeComments__box__foot">
              <div className="homeComments__box__foot__name"> Д.Ганчимэг</div>
              <div className="homeComments__box__foot__job">
                Ахисан шатны суралцагч
              </div>
            </div>
          </div>
        </Slide>

        <Slide spaceBetween={20}>
          <div className="homeComments__box">
            <figure className="homeComments-avatar">
              <img
                src={Picture1}
                alt="no file"
                onError={(event) => {
                  event.target.onerror = null;
                  event.target.src = noFile;
                }}
              />
            </figure>

            <div className="homeComments__box__text">
              Хичээлийг эхнээс нь дуустал маш шимтэн суралцсан. Энэ бол маргаан
              байхгүй сургалтын арга техникийн зөв байдлаас шалтгаалж байгааг
              хэлэх нь зүйтэй юм. Одоо ямар ч хослол дээр шинжилгээ хийж чаддаг
              болсон. Багшдаа баярлалаа.
            </div>
            <div className="homeComments__box__foot">
              <div className="homeComments__box__foot__name">С.Төмөр-очир</div>
              <div className="homeComments__box__foot__job">
                Ахисан шатны суралцагч
              </div>
            </div>
          </div>
        </Slide>
        <Slide spaceBetween={20}>
          <div className="homeComments__box">
            <figure className="homeComments-avatar">
              <img
                src={Picture3}
                alt="no file"
                onError={(event) => {
                  event.target.onerror = null;
                  event.target.src = noFile;
                }}
              />
            </figure>

            <div className="homeComments__box__text">
              Хичээлээ дахин дахин сонсож хийх тусам арга барилаа олж мундаг
              сайн арилжаачин болохын тулд хичээж байна. Тухайн хүн өөрөө
              үнэхээр сайн сурч чадвал бүхий л боломжуудыг зааж өгч чадсан
              Багшдаа болон ” IOTB” -ийнхаа хамт олонд баярлалаа.
            </div>
            <div className="homeComments__box__foot">
              <div className="homeComments__box__foot__name">А.Яанжиндулам</div>

              <div className="homeComments__box__foot__job">
                Ахисан шатны суралцагч
              </div>
            </div>
          </div>
        </Slide>

        <Slide spaceBetween={20}>
          <div className="homeComments__box">
            <figure className="homeComments-avatar">
              <img
                src={Picture2}
                alt="no file"
                onError={(event) => {
                  event.target.onerror = null;
                  event.target.src = noFile;
                }}
              />
            </figure>

            <div className="homeComments__box__text">
              2дахь элсэлтэнд хамрагдаж байсан. Зөвхөн форекс гэх биш өөр зөндөө
              дадал зуршил мэдлэг боловсролыг бий болгож өгсөн шифүдээ
              баярлалаа.
            </div>
            <div className="homeComments__box__foot">
              <div className="homeComments__box__foot__name">Э.Батбилэг</div>
              <div className="homeComments__box__foot__job">
                Ахисан шатны суралцагч
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  );
};

export default Comments;

import React, { useEffect, useRef } from 'react';

const noFile = require('../../../assets/no-file.png');

export const RenderVideoUploader = ({
  center,
  inputID,
  currentFile,
  haveFile,
  handleFileChanger,
}) => {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [currentFile]);

  return (
    <div
      className='fileUplaoder'
      style={{
        margin: center ? '0 auto' : '',
      }}
    >
      <input
        type='file'
        id={inputID}
        accept='video/mp4,video/x-m4v,video/*'
        onChange={handleFileChanger}
      />
      <label
        className='fileUplaoder__label fileUplaoder__label--top'
        htmlFor={inputID}
      >
        Файл сонгох
      </label>

      {haveFile ? (
        <video className='fileUplaoder__video' ref={videoRef} controls muted>
          <source src={currentFile} />
        </video>
      ) : (
        <figure className='fileUplaoder__figure fileUplaoder__figure--noIMG'>
          <img
            className='fileUplaoder__figure-img'
            src={currentFile}
            alt='no file'
            onError={(event) => {
              event.target.onerror = null;
              event.target.src = noFile;
            }}
          />
        </figure>
      )}
    </div>
  );
};

import HomeLayout from "../utils/Layouts/HomeLayout/HomeLayout";
import Banner from "../components/Home/Banner/Banner";
import CourseAbout from "../components/Home/CourseAbout/CourseAbout";
import HomeContact from "../components/Home/HomeContact/HomeContact";
import FreeCourse from "../components/Home/FreeCourse/FreeCourse";
import Partners from "../components/Home/Partners/Partners";
import HomeNews from "../components/Home/HomeNews/HomeNews";
import Comments from "../components/Home/Comments/Comments";

const Home = () => {
  return (
    <HomeLayout>
      <Banner />

      <CourseAbout />
      <HomeContact />

      <FreeCourse />
      <Partners />

      <HomeNews />

      <div className="homeBottomHeading">
        <h3 className="homeBottomHeading__title">Сэтгэгдлүүд</h3>
      </div>
      <Comments />
    </HomeLayout>
  );
};

export default Home;
